import { useEffect, useState } from 'react';
import AllRoutes from "../../AllRoutes";
import { GlobalUrl, GlobalImg } from "../../global";
import { Helmet, HelmetProvider } from "react-helmet-async";

const TermsAndConditions = () => {

    return (
        <HelmetProvider>
        <Helmet>
          <title>General Working Terms and Conditions | Sciative Solutions</title>
          <link rel="canonical" href={`${GlobalUrl}${AllRoutes.terms}`} />
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="csrf_token" content="" />
          <meta property="type" content="website" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="_token" content="" />
          <meta name="robots" content="noodp" />
          <meta name='description' content="Sciative solutions & its affiliates provide content on our websites &applications subject to these Terms of Service. We can update these terms so please check." />
          <meta property="og:locale" content="en_US" />
          <meta property="og:title" content="General Working Terms and Conditions | Sciative Solutions" />
          <meta property="og:description" content="Sciative solutions & its affiliates provide content on our websites &applications subject to these Terms of Service. We can update these terms so please check." />
         
          <meta property="og:hashtag" content="#ai" />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:url" content={`${GlobalUrl}${AllRoutes.terms}`} />
          <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
          <meta property="og:image"  content={GlobalImg} />
          <meta property="og:type" content="website" />
        </Helmet>
        <main className='bg-black m-0 py-4 text-white'>
            <div style={{ marginTop: '8em' }} className='w-100 bg-black container'>

                <h1 className='text-white bold'>Terms of Use</h1>

                <div className='mt-4'>
                    <p className='text-white thin'>
                    Sciative solutions pvt ltd. and its affiliates (“Sciative solutions” or “we”) provides its content on its websites or applications that post a link to this Terms of Use (the “Site”) subject to the following terms and conditions (the “Terms”). We may periodically change the Terms without prior notice, so please check back from time to time. These Terms were last updated on Jan 7, 2022. By accessing and using this Site, you agree to these Terms. For an explanation of Sciative solutions’s practices and policies related to the collection, use, and storage of our users’ information, please read our Privacy Policy.
                    </p>
                </div>

                <div className='mt-4'>
                    <h2 className='bold text-yellow'>1. Copyright</h2>
                    <p className='text-white thin'>All content and functionality on the Site, including text, graphics, logos, icons, images, and videos and the selection and arrangement thereof, in addition to any concepts, know-how, tools, frameworks, software, applications or other technology, algorithms, models, processes, and industry perspectives underlying or embedded in the foregoing, along with any enhancements to or derivative works thereof (the “Site Content”) is the exclusive property of Sciative solutions or its licensors and, to the extent applicable, is protected by U.S. and international copyright laws. Neither the Site Content nor functionality of the Site, may be copied, reproduced, modified, reverse engineered, altered (including the removal or disabling of any security or technological safeguards, disclaimers, or legends) uploaded, published, uploaded, posted, transmitted, or distributed in any way without our written permission, except for those uses specified in Section 3 – Use of site content. All rights not expressly granted are reserved.</p>
                </div>

                <div className='mt-4'>
                    <h2 className='bold text-yellow'>2. Trademarks</h2>
                    <p className='text-white thin'>The trademarks, service marks, designs, and logos (collectively, the “Trademarks”) displayed on the Site are the registered and unregistered Trademarks of Sciative solutions and its licensors. You agree that, except as expressly permitted by us (e.g., through social media sharing tools provided on the Site) or by our licensors, where applicable, you will not refer to or attribute any information to Sciative solutions or its licensors in any public medium (e.g., press release, websites, or public social media) for advertising or promotion purposes, or for the purpose of informing or influencing any third party and that you will not use or reproduce any Trademark of, or imply any endorsement by or relationship with, Sciative solutions or its licensors.</p>
                </div>

                <div className='mt-4'>
                    <h2 className='bold text-yellow'>3. Use of site content</h2>
                    <p className='text-white thin'>Sciative solutions hereby grants you a limited, non-exclusive, non-transferable, revocable license for the term hereof to access and download, display, and print one copy of the Site Content on any single computer solely for your internal, business use, provided that you do not modify the Site Content in any way (including creating derivative works thereof), that you retain all copyright and other proprietary notices displayed on the Site Content, and that you otherwise comply with these Terms. You may not otherwise reproduce, modify, reverse engineer, distribute, transmit, post, or disclose the Site Content without Sciative solutions’s prior written consent. In addition, you may not “mirror” the Site Content or any portion thereof without Sciative solutions’s express written consent. Nothing on this Site should be construed as granting directly or indirectly, or by implication any license or right to use any Sciative solutions intellectual property other than as expressly set forth herein. The license granted in this section terminates automatically and immediately if you do not comply with these Terms.</p>
                </div>

                <div className='mt-4'>
                    <h2 className='bold text-yellow'>4. User postings</h2>
                    <p className='text-white thin'>All content and functionality on the Site, including text, graphics, logos, icons, images, and videos and the selection and arrangement thereof, in addition to any concepts, know-how, tools, frameworks, software, applications or other technology, algorithms, models, processes, and industry perspectives underlying or embedded in the foregoing, along with any enhancements to or derivative works thereof (the “Site Content”) is the exclusive property of Sciative solutions or its licensors and, to the extent applicable, is protected by U.S. and international copyright laws. Neither the Site Content nor functionality of the Site, may be copied, reproduced, modified, reverse engineered, altered (including the removal or disabling of any security or technological safeguards, disclaimers, or legends) uploaded, published, uploaded, posted, transmitted, or distributed in any way without our written permission, except for those uses specified in Section 3 – Use of site content. All rights not expressly granted are reserved.</p>
                </div>

                <div className='mt-4'>
                    <h2 className='bold text-yellow'>5. Notices of infringement and takedown by Sciative solutions</h2>
                    <p className='text-white thin'>Sciative solutions prohibits the posting of any information that infringes or violates the copyright rights and/or other intellectual property rights (including rights of privacy and publicity) of any person or entity. If you believe that your intellectual property right (or such a right that you are responsible for enforcing) is infringed by any content on the Site, please write to Sciative solutions at the address shown below, giving a written statement that contains: (a) identification of the copyrighted work and/or intellectual property right claimed to have been infringed; (b) identification of the allegedly infringing material on the Site that is requested to be removed; (c) your name, address, and daytime telephone number, and an e-mail address if available; (d) a statement that you have a good faith belief that the use of the copyrighted work and/or exercise of the intellectual property right is not authorized by the owner, its agent, or the law; (e) a statement that the information in the notification is accurate, and, under penalty of perjury, that the signatory is authorized to act on behalf of the owner of the right that is allegedly infringed; and (f) the signature of the intellectual property right owner or someone authorized on the owner’s behalf to assert infringement of the right. Sciative solutions will remove any posted submission that infringes the copyright or other intellectual property right of any person.</p>
                </div>

                <div className='mt-4'>
                    <h2 className='bold text-yellow'>6. Disclaimers</h2>
                    <p className='text-white thin'>The content and functionality on the site is provided with the understanding that Sciative solutions is not herein engaged in rendering professional advice or services to you, no site content is intended to serve as or shall be deemed investment, legal, tax, accounting or other regulated advice, and that you shall remain solely responsible for your use of all site content and acknowledge that any reliance upon the site content shall be entirely at your sole option and risk. All content and functionality on the site is provided “as is,” without warranty of any kind, either express or implied, including, without limitation, implied warranties of merchantability and fitness for a particular purpose. Sciative solutions and its third-party content providers make no warranties, express or implied, as to the ownership, accuracy, or adequacy of the site content. Sciative solutions shall have no liability or responsibility for any information published on linked websites, contained in any user submissions published on the site, or provided by third parties. Neither Sciative Solutions nor its third-party content providers shall be liable for any indirect, incidental, consequential, or punitive damages or losses or for lost revenues or profits, whether or not advised of the possibility of such damages or losses and regardless of the theory of liability.</p>
                </div>

                <div className='mt-4'>
                    <h2 className='bold text-yellow'>7. Indemnification</h2>
                    <p className='text-white thin'>You hereby indemnify, defend, and hold harmless Sciative solutions and all of its predecessors, successors, parents, subsidiaries, affiliates, officers, directors, shareholders, investors, employees, agents, representatives, and attorneys and their respective heirs, successors, and assigns (“Sciative solutions Indemnified Parties”) from and against any and all liability, expenses, costs, or other losses (“Losses”) incurred by Sciative solutions and/or Sciative solutions Indemnified Parties in connection to any claims arising out of your use of the Site and/or any breach by you of these Terms, including the representations, warranties and covenants you made, if any, by agreeing to these Term. Sciative solutions reserves the right to assume, at its own expense, the exclusive defense and control of any matter otherwise subject to indemnification by you.</p>
                </div>

                <div className='mt-4'>
                    <h2 className='bold text-yellow'>8. Third-party websites & Providers</h2>
                    <p className='text-white thin'>We may provide links to third-party websites, and some of the content appearing to be on this Site is in fact supplied, supported, or provided directly or indirectly by third parties, for example, in instances of framing of third-party websites or incorporation through framesets of content supplied by third-party servers. Sciative solutions has no responsibility for these third-party websites, which are governed by the terms of use and privacy policies, if any, of the applicable third-party content providers.</p>
                </div>

               

            </div>
        </main>
        </HelmetProvider>
    )
};

export default TermsAndConditions;