import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import axios from 'axios';

import BlogsSection from './components/BlogsSection';
import ShortStories from "../../components/common/ShortStories";
// import SocialMedia from './components/SocialMedia';
import SocialMedia from "../../components/common/SocialMedia";
import ProductNumbers from '../../components/retail/productnumber';


import Form from '../../components/Forms/Form';
import { toast } from 'react-toastify';
import '../../assets/css/blogs.css'

import chats2 from '../../assets/img/chats2.png'
import notes2 from '../../assets/img/notes2.png'

import { GlobalUrl, GlobalImg } from '../../global';
import Contributor from '../../components/common/contributor';


const AllBlogs = () => {

    const [caseStudies, setCaseStudies] = useState([]);
    const [RATitle, setRATitle] = useState('');
    const [RAUrl, setRAUrl] = useState('');
    const [RAImg, setRAImg] = useState('');
    const [guestModal, setGuestModal] = useState(false);
    const [writerModal, setWriterModal] = useState(false);

    const props = {
        template: 2,
        form_name: "case-studies-catalogue", // Page name
        fields: [{
            name: "email",
            required: true
        }],
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res.status == "success") {
                toast.success('Form submitted successfully!');
            } else {
                toast.error(res.data)
            }
        }
    }

    const guestSpeakerProps = {
        template: 1,
        form_name: "become-a-guest-speaker",
        fields: [
            { name: "name", required: true },
            { name: "email", required: true },
            { name: "company", required: true },
            { name: "topic", required: true }
        ],
        title: "Become Our Guest Speaker",
        content_upper: "Got an amazing story to share? Become a featured guest on our podcast and share your insights with our audience. Submit your topic idea and let's make magic together on air.",
        content_lower: "Your voice could reach thousands of eager listeners!",
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res && res.status && res.status === "success") {
                setGuestModal(false);
                toast.success('Form submitted successfully!');
            }
            else {
                toast.error(res.data);
            }
        }
    }

    const guestWriterProps = {
        template: 1,
        form_name: "case-studies-catalogue-become-a-guest-writer",
        fields: [
            { name: "name", required: true },
            { name: "email", required: true },
            { name: "company", required: true },
            { name: "topic", required: true }
        ],
        title: "Become a Guest Writer",
        content_upper: "Got a great idea? We want to hear it! Submit your blog topic here and become a valued contributor to our site.",
        content_lower: "Your insights could reach thousands!",
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            toast.dismiss();
            setGuestModal(false);
            if (res.status == "success") {
                toast.success('Form submitted successfully!');
            } else {
                toast.error(res.data)
            }
        }
    }

    const getCaseStudies = async () => {
        try {
            const response = await axios.get(`/fetch_all_case_studies`, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setCaseStudies(response.data.filter(item => item['active'] === true))
            }
        } catch (err) {
            console.error("Error", err);
        }
    }

    const getRecentRATalk = async () => {
        try {
            const response = await axios.get(`/ra_talks/fetch_all_webinars`, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                // console.log(response.data)
                setRATitle(response.data[0]['title'])
                setRAUrl('/road-ahead-talks/' + response.data[0]['seo']['pageURL'])
                setRAImg(response.data[0]['pre-img']['pre-img-key'])
            }
        } catch (err) {
            console.error("Error", err);
        }
    }

    useEffect(() => {
        getCaseStudies()
        getRecentRATalk()
    }, [])

    return (
        <HelmetProvider>
            <Helmet>
                <title>Case Studies | Sciative Solutions</title>
                <link rel="canonical" href={GlobalUrl + '/road-ahead-talks/'} />
                <meta charset="utf-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="csrf_token" content="" />
                <meta property="type" content="website" />
                <meta name="theme-color" content="#ffffff" />
                <meta name="_token" content="" />
                <meta name="robots" content="noodp" />
                <meta name='description' content="Sciative Resources Dive Deep into all that's related to pricing - From strategy to AI, ML, Data Analysis and more... Subscribe to our weekly research articles for more data driven insights. All Browse by Topic Dynamic Pricing Competition Intelligence Browse by Industry E-commerce Intercity Coaches Retail Browse by Product Brio for Retail Viaje.ai for Bus" />
                <meta name='keywords' content="" />
                <meta content="image/*" property="og:image:type" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:image" content={GlobalImg} />
                <meta property="og:title" content="Sciative" />
                <meta property="og:hashtag" content="#ai" />
                <meta property="og:url" content='https://sciative.com' />
                <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
                <meta property="og:description" content="Sciative Resources Dive Deep into all that's related to pricing - From strategy to AI, ML, Data Analysis and more... Subscribe to our weekly research articles for more data driven insights. All Browse by Topic Dynamic Pricing Competition Intelligence Browse by Industry E-commerce Intercity Coaches Retail Browse by Product Brio for Retail Viaje.ai for Bus" />
                <meta property="og:type" content="website" />
            </Helmet>
            <main className='bg-beige'>
                <div className='w-100 bg-black py-3'>
                    <div className='w-90 container mt-5 py-5'>
                        <h4 className='mt-5 w-sm-100 text-white yellow-underline'>Case Studies</h4>
                        <h1 className='mt-4 bold text-white'>
                            Pricing Strategies In Action: Dive Deep With Sciative's Case Studies
                        </h1>

                        <div className='w-100 row mt-3'>
                            <div className='col-sm-12 col-md-6'>
                                <Form {...props} />
                            </div>
                            {/* <div className='col-6'>
                            <p className='thin text-white'>Subscribe to our weekly research articles for more data driven insights.</p>
                        </div>
                        <div className='w-100 row'>
                            <div className='col-6 p-1'>
                                <input className='w-100 input-box p-2' type='email' placeholder='Enter your email address' />
                            </div>
                            <div className='col-6 d-flex align-items-center'>
                                <button className='btn-yellow p-2 mx-2'>Subscribe</button>
                            </div>
                        </div> */}

                        </div>
                    </div>
                </div>

                <div className='mt-4 w-100 container'>
                    <BlogsSection showReadMore={true} blogs={caseStudies} />
                </div>

                <div className='mt-4 w-100 d-flex justify-content-center align-items-center'>
                    <button onClick={() => setWriterModal(true)} className='p-2 btn-black'>Become a Guest Writer <i class="mx-2 fa-solid fa-arrow-right"></i></button>
                </div>

                <div className='mt-4 p-3 py-5 container d-flex flex-column align-items-center'>
                    <div className='w-100 d-flex justify-content-center'>
                        <h4 className='bold'>Road Ahead Talks</h4>
                    </div>
                    <div className='mt-3 w-100 row'>
                        <div className='mb-3 col-sm-12 col-md-6 p-sm-1 p-md-3 p-lg-5 d-flex flex-column justify-content-center align-items-center'>
                            <h2 className='text-center bold text-black'>{RATitle}</h2>
                            <a href={RAUrl} className='text-center p-2 mt-3 btn-black'>Know more</a>
                        </div>
                        <div className='col-sm-12 col-md-6 p-2'>
                            <img alt='road-ahead' className='w-100 corner' src={RAImg} />
                        </div>
                    </div>
                </div>

                <div className='p-3 py-1 d-flex flex-sm-column flex-md-row align-items-center'>
                    <ShortStories />
                </div>

                <div className='mt-4 p-3 py-5 d-flex flex-sm-column flex-md-row align-items-center'>
                    <SocialMedia />
                </div>

                <Contributor type='dark' />



            </main>

        </HelmetProvider>
    )
}

export default AllBlogs;