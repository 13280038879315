import { useEffect, useState } from 'react';
import AllRoutes from "../../AllRoutes";
import { GlobalUrl, GlobalImg } from "../../global";
import { Helmet, HelmetProvider } from "react-helmet-async";

const CookiePolicy = () => {

    return (
        <HelmetProvider>
        <Helmet>
          <title>Our Cookie Policy | Sciative Solutions</title>
          <link rel="canonical" href={`${GlobalUrl}${AllRoutes.cookie}`} />
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="csrf_token" content="" />
          <meta property="type" content="website" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="_token" content="" />
          <meta name="robots" content="noodp" />
          <meta name='description' content="Learn about our cookie policy, ensuring transparency & compliance with data regulations, use of cookies to enhance your browsing experience respecting privacy." />
          <meta property="og:locale" content="en_US" />
          <meta property="og:title" content="Our Cookie Policy | Sciative Solutions" />
          <meta property="og:description" content="Learn about our cookie policy, ensuring transparency & compliance with data regulations, use of cookies to enhance your browsing experience respecting privacy." />
         
          <meta property="og:hashtag" content="#ai" />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:url" content={`${GlobalUrl}${AllRoutes.cookie}`} />
          <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
          <meta property="og:image"  content={GlobalImg} />
          <meta property="og:type" content="website" />
        </Helmet>
        <main className='bg-black m-0 py-4 text-white'>
            <div style={{ marginTop: '8em' }} className='w-100 bg-black container'>

                <h1 className='text-white bold'>Cookie Policy</h1>
                <p className='text-white thin'>Effective: January 7, 2022</p>

                <div className='mt-4'>
                    <p className='text-white thin'>
                        Sciative Solutions and its subsidiaries and affiliates (collectively, "Sciative Solutions Pvt. Ltd.", "us" or "we") understand that your privacy is important to you and are committed to being transparent about the technologies it uses. This Cookie Policy explains how and why cookies, web beacons, pixels, clear gifs, and other similar technologies (collectively "Cookies and Other Tracking Technologies") may be stored on and accessed from your device when you use or visit any website or app that posts a link to this Policy (collectively, "the Sites"). This Cookie Policy should be read together with our Privacy Policy and our Terms of Use.
                    </p>
                </div>

                <div className='mt-4'>
                    <h2 className='bold text-yellow'>What are Cookies and Other Tracking Technologies?</h2>
                    <p className='text-white thin'>
                        To the extent you agree, a cookie is a small text file that can be stored on and accessed from your device when you visit one of our sites. The other tracking technologies work similarly to cookies and place small data files on your devices or monitor your website activity to enable us to collect information about how you use our sites. This allows our sites to recognise your device from those of other users of the sites. The information provided below about cookies also applies to these other tracking technologies. You can find more information at www.allaboutcookies.org and www.youronlinechoices.eu.
                    </p>
                </div>

                <div className='mt-4'>
                    <h2 className='bold text-yellow'>How do our sites use cookies and other tracking technologies?</h2>
                    <p className='text-white thin'>
                        Sciative Solutions, Pvt. Ltd. uses cookies and other tracking technologies to identify you and your interests, to remember your preferences, and to track your use of our sites. We also use cookies and other tracking technologies to control access to certain content on our sites, protect the sites, and process any requests that you make to us. Sciative Solutions Pvt. Ltd. has also contracted with third-party service providers to track and analyse statistical usage and volume information from our site users in order to administer our sites and conduct research. These third-party service providers use persistent cookies to help us improve the user experience, manage our site content, and analyse how users navigate and utilise the sites.
                    </p>
                </div>

                <div className='mt-4'>
                    <h2 className='bold text-yellow'>First and Third Party Cookies</h2>
                    <p className='text-white thin'>"First party cookies" are cookies that belong to Sciative Solutions pvt. Ltd. and that Sciative Solutions pvt. Ltd. places on your device. "Third-party Cookies" are cookies that another party places on your device through our site. Sciative Solutions, Pvt. Ltd. may contract with third-party service providers to send e-mails to users who have provided us with their contact information. To help measure and improve the effectiveness of our e-mail communications and/or to determine whether messages have been opened and links clicked on, the third-party service providers may place cookies on the devices of these users. For more information on how these companies collect and use information on our behalf, please refer to their privacy policies as indicated below. Depending on the site, we use the following types of cookies:</p>
                    <p className='text-white thin'><span className='bold'>Persistent Cookies </span>We use persistent cookies to improve your experience of using the sites. This includes recording your acceptance of our cookie policy to remove the cookie message that first appears when you use the sites.</p>
                    <p className='text-white thin'><span className='bold'>Session Cookies </span>Session cookies are temporary and are deleted from your machine when your web browser closes. We use session cookies to help us track internet usage as described above. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting, you may be unable to access certain parts of the sites. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our sites. The data collected by the sites and/or through cookies that may be placed on your computer will not be kept for longer than is necessary to fulfill the purposes mentioned above. In any event, such information will not be kept for longer than one year. Our cookies are used for the following purposes:</p>
                    <ol>
                        <li><span className='bold'>Strictly Necessary/Technical: </span>These cookies are required for us to operate our sites and provide you with the services you have requested. These cookies, for example, let us recognise that you have created an account and have logged into that account to access site content. They also include cookies that enable us to remember your previous actions within the same browsing session and secure our sites. These cookies are used by us or third-party service providers to analyse how the sites are used and how they are performing. For example, these cookies track which pages are most frequently visited and from what locations our visitors come from. If you subscribe to a newsletter or otherwise register with the sites, these cookies may be associated with you. These cookies include, for example, Google Analytics cookies.</li>
                        <li><span className='bold'>Functionality: </span>These cookies let us operate the sites in accordance with the choices you make. These cookies permit us to "remember" you in-between visits. For instance, we will recognise your user name and remember how you customised the sites and services, for example, by adjusting text size, fonts, languages, and other parts of web pages that are alterable, and provide you with the same customizations during future visits.</li>
                        <li><span className='bold'>Third-Party Advertising: </span>These cookies collect information about your activities on these and other sites to provide you with targeted advertising. We may also allow our third-party service providers to use cookies on the sites for the same purposes identified above, including collecting information about your online activities over time and across different websites. The third-party service providers that generate these cookies, such as Adobe, Google, LinkedIn, Twitter, and Facebook, have their own privacy policies and may use their cookies to target advertising to you on other websites based on your visit to our sites.</li>
                    </ol>
                </div>

                <div className='mt-4'>
                    <h2 className='bold text-yellow'>How do I refuse or withdraw my consent to the use of cookies?</h2>
                    <p className='text-white thin'>If you do not want cookies to be dropped on your device, you can adjust the settings of your internet browser to reject the setting of all or some cookies and to alert you when a cookie is placed on your device. For further information about how to do so, please refer to your browser's "help" or "tool" or "edit" section or see www.allaboutcookies.org Please note that if you use your browser settings to block all cookies (including strictly necessary cookies), you may not be able to access or use all or parts or functionalities of our sites. If you want to remove previously-stored cookies, you can manually delete them at any time. However, this will not prevent the sites from placing further cookies on your device unless and until you adjust your internet browser settings as described above. For more information on the development of user-profiles and the use of targeting/advertising cookies, please see www.youronlinechoices.eu if you are located in Europe or www.aboutads.info/choices if you are in the United States.</p>
                </div>

                <div className='mt-4'>
                    <h2 className='bold text-yellow'>How do we use other tracking technologies in our communications?</h2>
                    <p className='text-white thin'>Sciative Solutions Pvt. Ltd. may collect information about whether you open or click any links in the knowledge, research, or event communications that we send to you through web beacons. A web beacon is a graphic image that is placed on a website or in an email that, alone or in conjunction with cookies, compiles information about your usage of or your interaction with a Sciative Solutions Pvt. Ltd. website or email. For example, we may add web beacons to the knowledge, research, or event communications that we send to you to determine whether you have opened our email or clicked a link. The analysis gathered helps us improve the effectiveness of the content and format of our websites and email communications.</p>
                </div>

                <div className='mt-4'>
                    <h2 className='bold text-yellow'>How do I refuse or withdraw my consent to the use of web beacons?</h2>
                    <p className='text-white thin'>You may avoid web beacons by configuring your email reader programme to disable the functionality that enables remote images to load and by refraining from clicking on any links in email messages.</p>
                </div>

                <div className='mt-4'>
                    <h2 className='bold text-yellow'>Link to third party sites</h2>
                    <p className='text-white thin'>Sciative Solutions may provide links to third party websites or information as a service to our users. If you use these links, you will leave the Sites. Such links do not constitute or imply an endorsement, sponsorship, or recommendation by Sciative Solutions of the third party, the third party website, or the information contained therein, and Sciative Solutions shall not be responsible or liable for your use thereof. Such use shall be subject to the terms of use and privacy policies applicable to those sites.</p>
                </div>

                <div className='mt-4'>
                    <h2 className='bold text-yellow'>Contact us</h2>
                    <p className='text-white thin'>If you have any questions or if you would like to communicate, please contact us at:
                        Sciative Solutions Pvt. Ltd.
                        info@sciative.com</p>
                </div>


            </div>
        </main>
        </HelmetProvider>
    )
};

export default CookiePolicy;