import Footer from "../common/footer";
import Header from "../common/header";
import Ticker from "../common/ticker";
import AOS from "aos";
import { useEffect, useState } from "react";
import "aos/dist/aos.css";

import '../../assets/css/main.scss';
import { Tab, Col, Nav, Row } from "react-bootstrap";
import investors from '../../assets/img/investors/bg.jpg';
import missionimg from '../../assets/img/investors/mission_img.png';
import venkatraju from '../../assets/img/investors/venkat-raju.png'
import indianretailer from '../../assets/img/media/indian-retailer.png';
import travelworld from '../../assets/img/media/travel-world.png';
import yourstory from '../../assets/img/media/your-story.png';
import rightarrowyellow from '../../assets/img/right-arrow-yellow.png'
import leader1img from '../../assets/img/anshu-jalora.jpg'
import leader2img from '../../assets/img/vijeta-soni.jpg'
import { Button } from 'react-bootstrap';  
import ClientBusSlider from "../travel/clientBusSlider";
import Leadership from "../retail/leadership";
import FounderVideo from "../retail/founderVideo";
import Recoginition from "../retail/recognition";
import ProductNumber from "../retail/productnumber";
import RaTalkSingle from "../retail/ratalksingle";
import TestimonialSlider from "../retail/testimonialslider";
import TravelBusInsights from "../travel/travelBusinsights";
import { Helmet, HelmetProvider } from "react-helmet-async";
import TravelProductNumber from "../travel/travelproductnumber";
import TestimonialSliderTravel from "../travel/testimonialslidertravel";
import CapteraAchievements from "../travel/capterra-achievvement";
import AllClientSlider from "../common/allClientSlider";
import SciativeMedia from "../common/sciative_media";
import History from "../common/history";
import { Link } from "react-router-dom";
import AllRoutes from "../../AllRoutes";
import WhatMakesSciativeUnique from "../common/whatMakesSciativeUnique";
import Form from "../Forms/Form";
import { toast } from 'react-toastify';

import { GlobalUrl, GlobalImg } from "../../global";
const Investors = ({  }) => {
  const formProps = {
    template: 4,
    form_name: 'book-a-demo-page-form',
    fields: [
      { name: 'name', required: true },
      { name: 'email', required: true },
      { name: 'company', required: true },
      { name: 'phone', required: true },
      { name: 'message', required: true },
    ],
    recipients_group_id: 1,
    handleApiResponse: (res) => {
        toast.dismiss();
        if (res.status == "success"){
          toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
        } else {
          toast.error(res.data)
      }
    }
}
useEffect(() => {
  AOS.init();
  AOS.refresh();
}, [])
    return (
        
          <HelmetProvider>
            <Helmet>
                <title>Invest in Future of AI Pricing the Scientific Way | Sciative</title>
                <link rel="canonical" href={`${GlobalUrl}${AllRoutes.investors}`} />
                <meta charset="utf-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="csrf_token" content="" />
                <meta property="type" content="website" />
                <meta name="theme-color" content="#ffffff" />
                <meta name="_token" content="" />
                <meta name="robots" content="noodp" />
                <meta name='description' content="Invest in the leading edge of pricing. Drive innovation and exceptional returns. Sharpen your investment portfolio with our value-generating strategies. " />
                <meta name='keywords' content="" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:title" content="Invest in Future of AI Pricing the Scientific Way | Sciative" />
                <meta property="og:description" content="Invest in the leading edge of pricing. Drive innovation and exceptional returns. Sharpen your investment portfolio with our value-generating strategies. " />
                <meta property="og:type" content="website" />

                <meta property="og:hashtag" content="#ai" />
                <meta content="image/*" property="og:image:type" />
                <meta property="og:url" content={`${GlobalUrl}${AllRoutes.investors}`} />
                <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
                <meta property="og:image"  content={GlobalImg} />
              
                
            </Helmet>
        <section className="smallBanner investors">
          <div className="container"  data-aos="new-animation">
                <p className="small_head">Investors</p>
                <h1 className="about_para">Invest in The Leading Edge of Pricing. Drive Innovation and Exceptional Returns. Sharpen Your Investment Portfolio With Our Value-Generating Strategies</h1>
                <p className="about_para_2">
                Be a part of our journey to build a global AI tech company from India.
                </p>

                <div>
                {/* <Button className="transparent-button">Discover more! </Button> */}
                <Link to={AllRoutes.investorscontact}>
                  <button className="yellow-button">Invest in Automated AI Tech Company</button>
                </Link>
                </div>
                
               

            </div>
        </section>
        <section className="investor_edge">
            <div className="container"  data-aos="new-animation">
              <h2 className="bigHead text-center mb-5">The Sciative Edge</h2>
            <Tab.Container defaultActiveKey="first">
                <Row>
                  <Col xl={5}>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Mission

                        <p>We have built powerful AI-powered dynamic price optimization and competition intelligence solutions, applicable across a diverse set of industries. Backed by years of deep domain knowledge and scientific rigor we bring unmatched, innovative tech capabilities, with speed, at scale. Sciative's technology has helped our customers add over <strong>$100Mn</strong> to their top line.</p>
                        {/* <img src={missionimg} alt="" /> */}
                        <div className="investor_edge_div res_img">
                            <img src={missionimg} alt="Mission Image" className=" "/>
                        </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Dr. Anshu Jalora, Founder & MD
                        <p>Dr. Anshu Jalora, Founder & Managing Director of Sciative Solutions is a recognized Pricing thought leader with over 23 years of rich experience in driving pricing transformation across globally recognized consumer and corporate brands. Before starting Sciative, Dr. Anshu was the Global Director of Pricing Strategy at Starbucks (USA), Director of Pricing at Overstoc.com (USA), Sr. Pricing Scientist (PROS Revenue Management). He is a topper from IIT Delhi (BTech) and a Ph.D. from Texas A&M University, USA. Dr. Anshu owns a patent in pricing software and has over 17 International Publications under his name.
                        </p>
                        <div className="investor_edge_div  res_img">
                            <img src={leader1img} alt="Dr. Anshu Jalora" className=" "/>
                        </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Vijeta Soni, Co-Founder & CEO
                        <p>Ms. Vijeta Soni, Co-Founder & CEO of Sciative Solutions, is a dynamic leader with over 17 years of experience in driving digital transformation across industries. An alumna of IIM Bangalore and the University of Houston, Texas, USA, she holds two master's degrees. Vijeta has led large-scale projects for companies like Bed Bath & Beyond, Reliance Industries, and Jio Institute. She was honored by Goldman Sachs as one of the top 10,000 Women Entrepreneurs in their program's history. In 2015, she co-founded Sciative to empower businesses with AI-powered dynamic pricing. Her leadership has optimized operations and fostered a high-performance culture at Sciative.</p>
                        <div className="investor_edge_div  res_img">
                            <img src={leader2img} alt="Vijeta Soni" className=" "/>
                        </div>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col xl={7} className="second_div">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div className="investor_edge_div">
                          <img src={missionimg} alt="Mission Image" />
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div className="investor_edge_div ">
                          <img src={leader1img} alt="Dr. Anshu Jalora" />
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <div className="investor_edge_div ">
                          <img src={leader2img} alt="Vijeta Soni" />
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>

            </div>
        </section>
        <AllClientSlider props="investor_slide_section" />
        {/* <Recoginition props="investor_page_section" /> */}
        <History />
        <Recoginition page_name="investor_page_section" />
        <section className="investor_details">
          <div className="container"  data-aos="new-animation">
            <h2 className="bigHead text-center mb-5">Word from our Investors</h2>
            <div className="card">

            
            <div className="row ">
              <div className="col-md-4 col-xl-2">
                <img src={venkatraju} alt="Venkat Raju" />
              </div>
              <div className="col-md-8 col-xl-10">
                <p className="investor_name">Venkat Raju</p>
              <p className="investor_company">Global CEO, Turbostart</p>
              <p className="investor_testimonial">Sciative, led by co-founders Dr. Anshu Jalora and Vijeta Soni, is a fast scaling product company - 100+ team serving several large customers globally. These customers have realized robust top line growth attributed to Sciative's differentiated "dynamic pricing" product. Sciative's deep domain depth in the space coupled with strong AI & tech capabilities sets them apart as a global leader. We are excited to be part of their ambitious journey.</p>
              </div>
            </div>
            </div>
          </div>
          
        </section>
        <WhatMakesSciativeUnique/>
        <section className="investor-result">
          <div className="container"  data-aos="new-animation">
            <h2 className="bigHead text-center mb-5">We Deliver Unparalleled Results</h2>
            <div className="row">
              <div className="col-md-4 num_border">
                <div className="num_div">
                  <p className="number">
                    22%
                  </p>
                  <p className="num_desc">
                  Repeat Purchase Growth

                  </p>
                </div>
                <p className="num_desc_2">
                India’s 2nd largest grocery retailer in just 2 months.
                </p>
              </div>
              {/* col-4 end */}
              <div className="col-md-4 num_border">
                <div className="num_div">
                  <p className="number">
                    99%
                  </p>
                  <p className="num_desc">
                  Forecast Accuracy

                  </p>
                </div>
                <p className="num_desc_2">
                of high-demand days and therefore profit maximisation for the bus industry
                </p>
              </div>
              {/* col-4 end */}
              <div className="col-md-4 num_border">
                <div className="num_div">
                  <p className="number">
                    20%
                  </p>
                  <p className="num_desc">
                  Topline Growth

                  </p>
                </div>
                <p className="num_desc_2">
                For a US based arts and crafts brand, by helping it get promotion and bundling pricing strategy right.
                </p>
              </div>
              {/* col-4 end */}
            </div>
          </div>
        </section>
      
        <SciativeMedia />
        <section className="investor-form-section">
        <div className="container d-flex flex-column align-items-center"  data-aos="new-animation">
          <div className="text-container">
            <h2 className="bigHead" data-aos="new-animation" class="aos-init aos-animate">Invest in Innovation with Us!</h2>
            <p>Our innovative approach unlocks hidden value and creates a win-win situation for all. 
              <br className="d-none d-md-block"/> Invest in the future of pricing and unlock next-gen value.</p>
          </div>
          <div className="form-container m-0">
            <Form {...formProps}/>
            
          </div>
        </div>
      </section>
        </HelmetProvider>
    );
}


export default Investors;