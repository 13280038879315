import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import { fetchToken } from '../../../Auth';

import { adminURL } from '../../../global';

import { toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

const Shorts = () => {
    const [raShorts, setRaShorts] = useState({})
    const [saveModal, setSaveModal] = useState(false)
    const [linkedInUrl, setLinkedInUrl] = useState('')

    const navigate = useNavigate();


    const getData = async () => {
        try {
            const response = await axios.get(`/fetch_ra_yt_link`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
                },
            });
            setRaShorts(response.data.raYTShorts || {});
            setLinkedInUrl(response.data.linkedInUrl || '');
        }
        catch (err) {
            console.error("Error", err);
        }
    }


    const setValue = (id, value) => {
        if (id === 'linkedIn') {
            setLinkedInUrl(value)
        }
        else {
            setRaShorts((prevState) => ({
                ...prevState,
                [id]: value
            }));
        }
    };


    const saveYTShorts = async () => {

        const { 0: link1, 1: link2, 2: link3, 3: link4 } = raShorts;
        const trimmedLinkedInUrl = linkedInUrl.trim();
        if (!trimmedLinkedInUrl || !trimmedLinkedInUrl.startsWith('https://www.linkedin.com/')) {
            toast.error('Please fill a valid LinkedIn URL.');
            return;
        }
        const trimmedLinks = [link1, link2, link3, link4].map(link => link.trim());
        if (trimmedLinks.some(link => !link)) {
            toast.error('Please fill all YouTube Shorts links.');
            return;
        }

        const isValidEmbedLink = (link) => {
            return link && (
                link.startsWith('https://www.youtube.com/embed/') ||
                link.startsWith('https://www.linkedin.com/') 
            );
        };
        if (trimmedLinks.some(link => !isValidEmbedLink(link))) {
            toast.error('Please fill links with valid embedded URLs.');
            return;
        }
        

        setSaveModal(true);
        let data = { raShorts: { ...raShorts, ...trimmedLinks }, linkedIn: trimmedLinkedInUrl };
        
        try {
            const response = await axios.post(`/edit_ra_yt_link`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setSaveModal(false)
                toast.success('Details saved successfully');
                navigate(`/niyantran-panel/${adminURL}/website-content`)
            }
        } catch (err) {
            setSaveModal(false)
            console.error("Error", err);
            if (err.response.status === 400) {
                setSaveModal(false)
                toast.error('This Blog URL already exists! Please change the Blog title or URL');
                return
            }
            if (err.response.status === 401) {
                navigate('/login')
            }
        }
    }

    useEffect(() => {
        getData()
    }, [])



    return (
        <>


            <Dialog
                open={saveModal}
                onClose={() => setSaveModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='sm'
            >
                <section className='w-100 row p-3'>
                    <div className='mb-4 w-100 py-4 d-flex flex-column align-items-center justify-content-center'>
                        <CircularProgress />
                        <h3 className='mt-4 bold'>Saving road ahead youtube shorts...</h3>
                        <p className=''>This may take a few seconds.</p>
                    </div>
                </section>
            </Dialog>

            <section className='w-100 row p-2'>
                <div className='admin-card p-3 my-3 d-flex flex-column'>
                    <div className='w-100 row'>
                        <div className='col-12 d-flex justify-content-between align-items-center'>
                            <div className='d-flex align-items-center'>
                            <h6 className='bold mb-0'>Website Content</h6>
                            {/* <Tooltip placement="right" size='small'
                                title="Go to LinkedIn or youtube shorts and navigate to the post you want to embed. Click on more options and choose copy embeded and copy the src from it"
                            >
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip> */}
                            </div>
                            <div className='w-20'>
                            <Button className='w-100 my-2' variant='contained' onClick={() => saveYTShorts()}>Add Content</Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='admin-card p-3 my-3 d-flex flex-column'>
                    <div className='w-100 row'>
                        <div className='col-12 d-flex justify-content-between align-items-end'>
                            <h6 className='bold'>LinkedIn embed<span className='text-danger'>*</span></h6>
                            <Tooltip placement="right" size='small' title="Go to LinkedIn and navigate to the post you want to embed.Click on more options and choose copy embeded and copy the src from it" >
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <input value={linkedInUrl} className='form-control medium-2' type='text' onChange={(evt) => setValue('linkedIn', evt.target.value)} />
                </div>

                <div className='admin-card p-3 my-3 d-flex flex-column'>
                    <div className='w-100 row'>
                        <div className='col-12 d-flex justify-content-between align-items-end'>
                            <h6 className='bold'>Shorts Link - 1<span className='text-danger'>*</span></h6>
                            <Tooltip placement="right" size='small' title="Go to Youtube Shorts or LinkedIn and navigate to the post you want to embed.Click on more options and choose copy embeded and copy the src from it" >
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <input value={raShorts[0]} className='form-control medium-2' type='text' onChange={(evt) => setValue(0, evt.target.value)} />
                </div>
                <div className='admin-card p-3 my-3 d-flex flex-column'>
                    <div className='w-100 row'>
                        <div className='col-12 d-flex justify-content-between align-items-end'>
                            <h6 className='bold'>Shorts Link - 2 <span className='text-danger'>*</span></h6>
                            <Tooltip placement="right" size='small' title="Go to Youtube Shorts or LinkedIn and navigate to the post you want to embed.Click on more options and choose copy embeded and copy the src from it" >
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <input value={raShorts[1]} className='form-control medium-2' type='text' onChange={(evt) => setValue(1, evt.target.value)} />
                </div>
                <div className='admin-card p-3 my-3 d-flex flex-column'>
                    <div className='w-100 row'>
                        <div className='col-12 d-flex justify-content-between align-items-end'>
                            <h6 className='bold'>Shorts Link - 3 <span className='text-danger'>*</span></h6>
                            <Tooltip placement="right" size='small' title="Go to Youtube Shorts or LinkedIn and navigate to the post you want to embed. Click on more options and choose copy embeded and copy the src from it" >
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <input value={raShorts[2]} className='form-control medium-2' type='text' onChange={(evt) => setValue(2, evt.target.value)} />
                </div>
                <div className='admin-card p-3 my-3 d-flex flex-column'>
                    <div className='w-100 row'>
                        <div className='col-12 d-flex justify-content-between align-items-end'>
                            <h6 className='bold'>Roadahead Shorts Youtube Link - 4 <span className='text-danger'>*</span></h6>
                            <Tooltip placement="right" size='small' title="Go to Youtube Shorts or LinkedIn and navigate to the post you want to embed. Click on more options and choose copy embeded and copy the src from it" >
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <input value={raShorts[3]} className='form-control medium-2' type='text' onChange={(evt) => setValue(3, evt.target.value)} />
                </div>

{/* 
                <div className='admin-card p-3 my-3 d-flex w-20'>
                    <Button className='w-100 my-2' variant='contained' onClick={() => saveYTShorts()}>Add Content</Button>
                </div> */}
            </section>
        </>
    )
}

export default Shorts;