import Footer from "../common/footer";
import Header from "../common/header";
import Ticker from "../common/ticker";
import { Button } from 'react-bootstrap';
import AllRoutes from "../../AllRoutes";
import InnerCard from "../retail/innercard";

import img1 from "../../assets/img/retail/automated-pricing/1.png";
import img2 from "../../assets/img/retail/automated-pricing/2.png";
import img3 from "../../assets/img/retail/automated-pricing/3.png";
import cta_bg from '../../assets/img/retail/automated-pricing/bg_cta.png'
import shopify from '../../assets/img/partner/integration_2/shopify.png'
import amazon from '../../assets/img/partner/integration_2/amazon.png'
import flipkart from '../../assets/img/partner/integration_2/flipkart.png'
import clientimg from '../../assets/img/clients/rupesh-patil.png'
import kkimg from '../../assets/img/retail-client/kisan-konnect.png'
import img4 from "../../assets/img/retail/automated-pricing/4.png";
import img5 from "../../assets/img/retail/automated-pricing/5.png";
import img6 from "../../assets/img/retail/automated-pricing/6.png";
import img7 from "../../assets/img/retail/automated-pricing/7.png";
import img8 from "../../assets/img/retail/automated-pricing/8.png";
import img9 from "../../assets/img/retail/automated-pricing/9.png";
import bggoal from "../../assets/img/store/goal-driven-pricing.png";
import bgunified from "../../assets/img/store/unified-pricing.png";
import bgcomp from "../../assets/img/store/competition-intelligence.png";
import bgautomated from "../../assets/img/store/automated-pricing.png";


import { Tab, Col, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Recoginition from "../retail/recognition";
import CapteraAchievements from "../travel/capterra-achievvement";
import RetailHeader from "../retail/retailHeader";
import RetailClient from "../retail/retailCLient";
import AOS from "aos";
import { useEffect, useState } from "react";
import "aos/dist/aos.css";
import QAScore from "../retail/QAScore";
import InnerHeroRetail from "../retail/innerHeroRetail";
import RetailInsideNumber from "../retail/retailInsideNumber";
import RetailBenefits from "../retail/retailBenefits";
import MarketPlaceIntegration from "../retail/marketPlaceIntegration";
import RetailTestimonial from "../retail/retailTestimonial";
import { toast } from "react-toastify";
import Form from "../Forms/Form";
import { Helmet, HelmetProvider } from "react-helmet-async";

import img1_ from "../../assets/img/retail/competition-intelligence/real_time_market_monitoring.png";
import img2_ from "../../assets/img/retail/competition-intelligence/assortment_availability.png";
import img3_ from "../../assets/img/retail/competition-intelligence/product_matching.png";
import img4_ from "../../assets/img/retail/competition-intelligence/data_driven.png";
import NavigationSection from "../retail/NavigationSection";
import BenefitSection from "../retail/BenefitSection";
import { GlobalUrl, GlobalImg } from "../../global";
import b1 from "../../assets/img/icon/competitive.png";
import b2 from "../../assets/img/icon/crawling.png";
import b3 from "../../assets/img/icon/Assortment.png";
import b4 from "../../assets/img/icon/Analysis.png";
import b5 from "../../assets/img/icon/competitive.png";
import b6 from "../../assets/img/icon/Alerts.png";



const CompetitionIntelligence = ({ }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])
  const end_point = "competition-intelligence";

  const qa_list = [
    "Does your solution leverage cutting-edge technology to automatically capture and analyze competitor pricing data in real-time?",
    "Does your system track competitor pricing and provide data-driven insights into their market positioning?",
    "Do you get real-time updates on your competitors' pricing to help you stay competitive?",
    "Does your analyst spend many hours trying to analyse competition data?"
  ];

  const formProps = {
    template: 4,
    form_name: 'retail-inside-pages',
    fields: [
      { name: 'name', required: true },
      { name: 'email', required: true },
      { name: 'company', required: true },
      { name: 'phone', required: true },
      { name: 'message', required: true },
    ],
    recipients_group_id: 1,
    handleApiResponse: (res) => {
      toast.dismiss();
      if (res.status == "success"){
        toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
      } else {
        toast.error(res.data)
    }
    }
  }
  const navigation_section_props = {
    id: "competition_intelligence",
    heading: "Why Monitoring Competition Intelligence Is Important?",
    tabs: [
        {
          title: "Real-Time Market Data Monitoring",
          desc: "Strengthen your market position by continuously tracking real-time changes in prices, product availability, trends, and competitor pricing.",
          img: img1_ ,
          alt: "Real-Time Market Data Monitoring" 
        },
        {
          title: "Assortment and Availability",
          desc: "Uncover what makes your products unique in the market by diving into a comparative analysis of your product assortment against competitors.",
          img: img2_,
          alt: "Assortment and Availability"
        },
        {
          title: "Product Matching",
          desc: "Harness the power of our advanced matching engine, with a remarkable 99% accuracy rate in global tracking and competitor product comparison, thereby reducing manual errors and increasing efficiency.",
          img: img3_,
          alt: "Product Matching"
        },
        {
          title: "Beat the Competition by a High Margin",
          desc: "With 99% product matching accuracy and near real-time refresh data rates, our solution stays on top of your competitors’ price movements to increase product margins leading to higher revenue sales.",
          img: img4_,
          alt: "Beat the Competition by a High Margin"
        }
    ]
  }
  const benefit_props = {
    id: "competition_intelligence",
    heading: "Benefits of Using BRIO",
    tabs:[
      {
        title: "Strategic Competitive Position",
        desc: "Create your customised library of common pricing rules and constraints.",
        img: b1 ,
        alt: "Strategic Competitive Position Image" 
      },
      {
        title: "Comprehensive Web Crawling",
        desc: "Our AI-driven web crawlers provide real-time competition intelligence, tracking competitor's pricing and market changes, with advanced competitor intelligence tools.",
        img: b2 ,
        alt: "Comprehensive Web Crawling" 
      },
      {
        title: "Comparative Assortment Analysis",
        desc: "Craft targeted marketing and pricing strategies that reflect the distinctiveness of your product range to seize market opportunities.",
        img: b3 ,
        alt: "Comparative Assortment Analysis" 
      },
      {
        title: "Market Analysis",
        desc: "Empower businesses with data-driven competition intelligence to further enhance their market presence and competitiveness.",
        img: b4 ,
        alt: "Market Analysis" 
      },
      {
        title: "Dynamic Competitiveness Score",
        desc: "We employ a dynamic scoring system that adapts to changes in the market. It categorizes products into either Lower (L), Competitive (C), or Higher (H) based on real-time market parameters.",
        img: b5 ,
        alt: "Dynamic Competitiveness Score" 
      },
      {
        title: "Automated Alerts",
        desc: "Our automated alerts and notifications, enabling you to stay proactive and responsive to market dynamics.",
        img: b6 ,
        alt: "Automated Alerts" 
      },

    ]
  }

  return (
    <>

      <HelmetProvider>
        <Helmet>
          <title>Competition Intelligence Tool For Retail Industry | Sciative</title>
          <link rel="canonical" href={`${GlobalUrl}${AllRoutes.competitionintpage}`} />
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="csrf_token" content="" />
          <meta property="type" content="website" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="_token" content="" />
          <meta name="robots" content="noodp" />
          <meta name='description' content="Competition Intelligence equips you with real time insights into competitor pricing, promotions, and inventory, empowering them to make data driven decisions." />
          <meta property="og:locale" content="en_US" />
          <meta property="og:title" content="Competition Intelligence Tool For Retail Industry | Sciative" />
          <meta property="og:description" content="Competition Intelligence equips you with real time insights into competitor pricing, promotions, and inventory, empowering them to make data driven decisions." />
          <meta property="og:hashtag" content="#ai" />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:url" content={`${GlobalUrl}${AllRoutes.competitionintpage}`} />
          <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
          <meta property="og:image"  content={GlobalImg} />
          <meta property="og:type" content="website" />
          <script type="application/ld+json">
              {`
               {
                "@context": "https://schema.org/", 
                "@type": "BreadcrumbList", 
                "itemListElement": [{
                  "@type": "ListItem", 
                  "position": 1, 
                  "name": "Home",
                  "item": "https://sciative.com"  
                },{
                  "@type": "ListItem", 
                  "position": 2, 
                  "name": "Retail",
                  "item": "https://sciative.com/retail"  
                },{
                  "@type": "ListItem", 
                  "position": 3, 
                  "name": "Unified Pricing Engine",
                  "item": "https://sciative.com/retail/retail-competition-intelligence"  
                }]
              }
              
                
              `}
            </script>
        </Helmet>


        <InnerHeroRetail end_point={end_point} page_name="competitionintelligence" page_heading="Competition Intelligence in Retail
" page_desc1="Easily track your competitors' pricing " page_desc2="in real-time." button_name="Explore Competitor Intelligence Tool"/>
        <RetailClient />

        <NavigationSection {...navigation_section_props} />
        <RetailInsideNumber end_point={end_point} />
        {/* <RetailBenefits /> */}
        <BenefitSection {...benefit_props} />
        <MarketPlaceIntegration  end_point={end_point} />
        <RetailTestimonial dataHeading="We Deliver, Our Customers ‘ Speak" />
        {/* <section className="client_det auto_price">
            <div className="container">
                <h2 className="bigHead mb-5">We Deliver, Our Customers ‘ Speak </h2>
                <div className="card">
                    <div className="flex_card">
                        <div className="card_1">
                            <img src={clientimg} alt="Rupesh Patil" />
                            <div className="client_desc">
                                <p className="namec">Rupesh Patil,</p>
                                <p className="named">Senior Manager MIS, Costing & Business Development</p>
                            </div>
                        </div>
                       
                        <img src={kkimg} alt="" className="kk_img" />
                    </div>
                    <p className="client_test">What I liked best about working with Sciative is their quest to understand the Customer’s need rather than pushing any of their products (which are great, by the way). The team is absolutely professional and the output work is very commendable. It has helped our business take key timely decisions.</p>
                </div>
                <div className="text-center client_det_para">
                        <p>If they could, even your brand can!</p>
                        <Link to='/retail/book-a-demo' className="yellow-button">Connect Now</Link>
                </div>
            </div>
        </section> */}
        <CapteraAchievements props={"brio_inside"} />

        <QAScore end_point={end_point} qa_list={qa_list} />

        <section className="qa_store">
          <div className="container"  data-aos="new-animation">
            <h2 className="bigHead text-center mb-5">What all is in store for you ?</h2>
            <div className="row">
              <div className="col-md-4">
                <InnerCard headImg={bgunified} pageheading="Unified Pricing Engine" pagelink={AllRoutes.unifiedpricingpage} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bgautomated} pageheading="Automated Pricing Engine" pagelink={AllRoutes.automatedpricingpage} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bggoal} pageheading="Goal Driven Pricing" pagelink={AllRoutes.goalbasedpage} />

              </div>
            </div>
          </div>
        </section>

        <section className="form-section">
          <div className="container"  data-aos="new-animation">
            <div className="text-container">
              <h2 className="bigHead mb-5">Curious to know more about us!</h2>
              <p>Explore more about how our pricing experts  can scale your revenue to soaring heights just by pricing right.</p>
            </div>
            <div className="form-container">
              <Form {...formProps} />
            </div>
          </div>
        </section>

      </HelmetProvider>
    </>
  )
}
export default CompetitionIntelligence;