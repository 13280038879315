import Footer from "../common/footer";
import Header from "../common/header";
import Ticker from "../common/ticker";
import { Button } from 'react-bootstrap';
import AllRoutes from "../../AllRoutes";
import InnerCard from "../retail/innercard";
import img1 from "../../assets/img/retail/automated-pricing/Real-Time-Market-Monitoring-updated.png";
import img2 from "../../assets/img/retail/automated-pricing/assortment_availability.png";
import img3 from "../../assets/img/retail/automated-pricing/product_matching.png";
import cta_bg from '../../assets/img/retail/automated-pricing/bg_cta.png'
import shopify from '../../assets/img/partner/integration_2/shopify.png'
import amazon from '../../assets/img/partner/integration_2/amazon.png'
import flipkart from '../../assets/img/partner/integration_2/flipkart.png'
import clientimg from '../../assets/img/clients/rupesh-patil.png'
import kkimg from '../../assets/img/retail-client/kisan-konnect.png'
import img4 from "../../assets/img/retail/automated-pricing/4.png";
import img5 from "../../assets/img/retail/automated-pricing/5.png";
import img6 from "../../assets/img/retail/automated-pricing/6.png";
import img7 from "../../assets/img/retail/automated-pricing/7.png";
import img8 from "../../assets/img/retail/automated-pricing/8.png";
import img9 from "../../assets/img/retail/automated-pricing/9.png";
import bggoal from "../../assets/img/store/goal-driven-pricing.png";
import bgunified from "../../assets/img/store/unified-pricing.png";
import bgcomp from "../../assets/img/store/competition-intelligence.png";
import bgautomated from "../../assets/img/store/automated-pricing.png";
import { GlobalUrl, GlobalImg } from "../../global";

import { Tab, Col, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Recoginition from "../retail/recognition";
import CapteraAchievements from "../travel/capterra-achievvement";
import RetailHeader from "../retail/retailHeader";
import RetailClient from "../retail/retailCLient";
import AOS from "aos";
import { useEffect, useState } from "react";
import "aos/dist/aos.css";
import QAScore from "../retail/QAScore";
import InnerHeroRetail from "../retail/innerHeroRetail";
import RetailInsideNumber from "../retail/retailInsideNumber";
import RetailBenefits from "../retail/retailBenefits";
import MarketPlaceIntegration from "../retail/marketPlaceIntegration";
import RetailTestimonial from "../retail/retailTestimonial";
import Form from "../Forms/Form";
import { toast } from "react-toastify";
import { Helmet, HelmetProvider } from "react-helmet-async";
import NavigationSection from "../retail/NavigationSection";
import BenefitSection from "../retail/BenefitSection";
import b1 from "../../assets/img/icon/competitive.png";
import b2 from "../../assets/img/icon/crawling.png";
import b3 from "../../assets/img/icon/Assortment.png";
import b4 from "../../assets/img/icon/Analysis.png";
import b5 from "../../assets/img/icon/Score.png";
import b6 from "../../assets/img/icon/Alerts.png";
import pimg1 from "../../assets/img/picon/1.png";
import pimg2 from "../../assets/img/picon/2.png";
import pimg3 from "../../assets/img/picon/3.png";
import pimg4 from "../../assets/img/picon/4.png";
import pimg5 from "../../assets/img/picon/5.png";
import ProblemSection from "../retail/ProblemSection";
const MarketPricing = ({ }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])
  const end_point = "market-based-pricing";

  const qa_list = [
    "Have you experienced challenges in adjusting prices quickly in response to competitor pricing changes?",
    "Do you find it difficult to track and monitor pricing data across multiple products and categories?",
    "Have you missed opportunities to optimize prices based on real-time market demand and trends?",
    "Is it challenging to ensure pricing consistency across different sales channels and locations?",
  ];

  const formProps = {
    template: 4,
    form_name: 'retail-inside-pages',
    fields: [
      { name: 'name', required: true },
      { name: 'email', required: true },
      { name: 'company', required: true },
      { name: 'phone', required: true },
      { name: 'message', required: true },
    ],
    recipients_group_id: 1,
    handleApiResponse: (res) => {
      toast.dismiss();
      if (res.status == "success"){
        toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
    } else {
        toast.error(res.data)
    }
    }
  }
  const navigation_section_props = {
    id: "comp_tracking",
    heading: "Industry problems that Our Market Driven Pricing Solution Solves",
    tabs: [
        {
          title: "Inability to quickly adapt prices in response to competitor moves results in lost market share and diminished profitability.",
          img: pimg1 ,
          alt: "Inability to quickly adapt prices in response to competitor moves results in lost market share and diminished profitability.",
          correcttitle: "BRIO outpaces competitors! Dynamic pricing reacts instantly to market moves, maximizing profit and market share."
        },
        {
          title: "Failure to adjust prices for market conditions and consumer demand peaks leads to missed revenue opportunities.",
          img: pimg2,
          alt: "Failure to adjust prices for market conditions and consumer demand peaks leads to missed revenue opportunities.",
          correcttitle: "BRIO captures every peak! AI adjusts prices for market conditions and demand peaks, boosting revenue."
        },
        {
          title: "Relying on outdated market data prevents alignment with current market trends and customer preferences, affecting competitiveness.",
          img: pimg3,
          alt: "Relying on outdated market data prevents alignment with current market trends and customer preferences, affecting competitiveness.",
          correcttitle: "BRIO keeps you relevant! Real-time data fuels pricing that aligns with current trends and preferences."
        },
        {
          title: "Inaccurate pricing causes inventory mismanagement, leading to overstock and stockouts, which tie up capital and impact sales.",
         
          img: pimg4,
          alt: "Inaccurate pricing causes inventory mismanagement, leading to overstock and stockouts, which tie up capital and impact sales.",
          correcttitle: "BRIO prevents stock issues! Accurate pricing optimizes inventory, avoiding overstock & stockouts."
        },
        {
          title: "Rigid pricing structures limit responsiveness to market changes, hindering growth and revenue potential.",
         
          img: pimg5,
          alt: "Rigid pricing structures limit responsiveness to market changes, hindering growth and revenue potential.",
          correcttitle: "BRIO unlocks growth! Flexible pricing structures empower you to capitalize on changing markets."
        },
    ]
  }
  const benefit_props = {
    id: "comp_price",
    heading: "Benefits of Using BRIO",
    tabs:[
      {
        title: "Strategic Competitive Position",
        desc: "Create your customised  library of common pricing rules and constraints ",
        img: b1 ,
        alt: "Strategic Competitive Position Image" 
      },
      {
        title: "Comprehensive Web Crawling",
        desc: "Our AI-driven web crawlers provide real-time competition intelligence, tracking competitor's pricing and market changes, with advanced competitor intelligence tools.",
        img: b2 ,
        alt: "Comprehensive Web Crawling" 
      },
      {
        title: "Comparative Assortment Analysis",
        desc: "Craft targeted marketing and pricing strategies that reflect the distinctiveness of your product range to seize market opportunities.",
        img: b3 ,
        alt: "Comparative Assortment Analysis" 
      },
      {
        title: "Competitor Analysis and Alerts",
        desc: "Empower businesses with data-driven decision-making tools to further enhance their market presence and competitiveness.",
        img: b4 ,
        alt: "Competitor Analysis and Alerts" 
      },
      {
        title: "Dynamic Competitiveness Score",
        desc: "Employs a dynamic scoring system that adapts to changes in the market. It categorizes each product into either Lower (L), Competitive (C), or Higher (H) based on the real-time market.",
        img: b5 ,
        alt: "Dynamic Competitiveness Score" 
      },
      {
        title: "Automated Alerts",
        desc: "Our automated alerts and notifications, enabling you to stay proactive and responsive to market dynamics.",
        img: b6 ,
        alt: "Automated Alerts" 
      },

    ]
  }
  const navigation_section_props_2 = {
    id: "compptracking",
    heading: "How Does Automated Pricing Use Real-Time Data to Enhance Your Competitiveness?",
    tabs: [
        {
          title: "Real Time Market Monitoring",
          desc: "Bolster your competition presence by real-time monitoring of price fluctuations, product availability, trends, and competitor pricing. ",
          img: img1,
          alt: "Real Time Market Monitoring" 
        },
        {
          title: "Assortment and Availability",
          desc: "Uncover what makes your products unique in the market by diving into a comparative analysis of your product assortment against competitors.",
          img: img2,
          alt: "Assortment and Availability"
        },
        {
          title: "Product Matching",
          desc: "Harness the power of our Advanced Matching Engine, boasting a remarkable 99% accuracy rate in global tracking and competitor product comparison, thereby reducing human errors and increasing efficiency.",
          img: img3,
          alt: "Product Matching"
        }
    ]
  }
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Market Based Pricing Tool for Retail Industry | Sciative</title>
          <link rel="canonical" href={`${GlobalUrl}${AllRoutes.marketpricing}`} />
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="csrf_token" content="" />
          <meta property="type" content="website" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="_token" content="" />
          <meta name="robots" content="noodp" />
          <meta name='description' content="Market-Based Pricing Tool empowers retailers to set dynamic prices that reflect real-time market conditions, competitor pricing, & customer demand. Connect now." />
          <meta property="og:locale" content="en_US" />
          <meta property="og:title" content="Market Based Pricing Tool for Retail Industry | Sciative" />
          <meta property="og:description" content="Market-Based Pricing Tool empowers retailers to set dynamic prices that reflect real-time market conditions, competitor pricing, & customer demand. Connect now." />
         
          <meta property="og:hashtag" content="#ai" />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:url" content={`${GlobalUrl}${AllRoutes.marketpricing}`} />
          <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
          <meta property="og:image"  content={GlobalImg} />
          <meta property="og:type" content="website" />
          <script type="application/ld+json">
              {`
              {
                "@context": "https://schema.org/", 
                "@type": "BreadcrumbList", 
                "itemListElement": [{
                  "@type": "ListItem", 
                  "position": 1, 
                  "name": "Home",
                  "item": "https://sciative.com"  
                },{
                  "@type": "ListItem", 
                  "position": 2, 
                  "name": "Retail",
                  "item": "https://sciative.com/retail"  
                },{
                  "@type": "ListItem", 
                  "position": 3, 
                  "name": "solution",
                  "item": "https://sciative.com/retail/market-based-pricing"  
                }]
              }
              
              `}
            </script>
        </Helmet>
        <InnerHeroRetail end_point={end_point} page_name="marketpricing" page_heading="Market Driven Pricing" page_desc1="Outsmart Rivals, Win Big: Understand Retail Trends, " page_desc2="Excel Always!" button_name="Try Automated Pricing Today" />
        <RetailClient />

        {/* <section className="investor_edge content_edge">
            <div className="container">
              <h2 className="bigHead text-center mb-5">Industry problems that Our Solution Solves</h2>
            <Tab.Container defaultActiveKey="first">
                <Row>
                  <Col xl={5}>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Loss of Competitivenes due to lack of real-time insights into competitor pricing strategies potentially sacrificing margins.

                        <div className="investor_edge_div res_img">
                           <img src={img1} alt="Loss of Competitivenes due to lack of real-time insights into competitor pricing strategies potentially sacrificing margins." />  
                        </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Missed opportunities to capitalize on market trends and customer preferences due to inefficient pricing startegies on similar products.
                        <div className="investor_edge_div res_img">
                           <img src={img2} alt="Missed opportunities to capitalize on market trends and customer preferences due to inefficient pricing startegies on similar products." />  
                        </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Limited understanding of market dynamics and consumer behavior leads to ineffective product positioning and missed opportunities for targeted marketing campaigns.
                         <div className="investor_edge_div res_img">
                           <img src={img3} alt="Limited understanding of market dynamics and consumer behavior leads to ineffective product positioning and missed opportunities for targeted marketing campaigns."/>  
                        </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fourth">Inaccurate pricing decisions  directly impacting  inventory management leading to stockouts or excess inventory.
                         <div className="investor_edge_div res_img">
                           <img src={img3} alt="Inaccurate pricing decisions  directly impacting  inventory management leading to stockouts or excess inventory."  />  
                        </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fifth">Reduced / stagnant revenue generation due to  icorrect pricing
                         <div className="investor_edge_div res_img">
                           <img src={img3} alt="Reduced / stagnant revenue generation due to  icorrect pricing"  />  
                        </div>
                        </Nav.Link>
                      </Nav.Item>
                 
                    </Nav>
                  </Col>
                  <Col xl={7} className="second_div">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div className="investor_edge_div">
                          <img src={img1} alt="Inconsistent pricing across multiple channels" />
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div className="investor_edge_div">
                          <img src={img2} alt="Lack of data driven  and actionable insights" />
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <div className="investor_edge_div">
                          <img src={img3} alt="Difficulty in Monitoring Product Availability" />
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                        <div className="investor_edge_div">
                          <img src={img3} alt="Lack of Data-Driven Insights" />
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="fifth">
                        <div className="investor_edge_div">
                          <img src={img3} alt="Manual Price Updates" />
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>

            </div>
        </section> */}
      
        <ProblemSection {...navigation_section_props} />
        <section className="client_det inside_det">
          <div className="container" data-aos="new-animation">
            <h2 className="bigHead mb-5">We Deliver, Our Customers ‘ Speak </h2>
            <div className="card">

              <p className="client_test">Since implementing BRIO's automated pricing tool, our business has seen a remarkable transformation. The ability to dynamically adjust prices based on real-time market data has resulted in a significant increase in our profitability. We've been able to stay competitive, capture market trends, and respond swiftly to changing customer demands. Brio has truly revolutionised our pricing strategy, and we couldn't be happier with the results.</p>
              <div className="flex_card">
                <div className="card_1">
                  {/* <img src={clientimg} alt="Rupesh Patil" /> */}
                  <div className="client_desc">
                    <p className="namec">Private Label Fashion Brand</p>
                    <p className="named">North America</p>
                  </div>
                </div>

                {/* <img src={kkimg} alt="" className="kk_img" /> */}
              </div>
            </div>
            <div className="text-center mt-5">
              <p className="bold">If they could, even your brand can!</p>
              <Link to={`/retail/book-a-demo?navigation=${end_point}`}>
                <button class="yellow-button">Connect now</button>
              </Link>
            </div>
          </div>
        </section>
        <RetailInsideNumber end_point={end_point} />
        {/* <RetailBenefits /> */}
        <BenefitSection {...benefit_props} />


        {/* <section className="client_det auto_price">
            <div className="container">
                <h2 className="bigHead mb-5">We Deliver, Our Customers ‘ Speak </h2>
                <div className="card">
                    <div className="flex_card">
                        <div className="card_1">
                            <img src={clientimg} alt="Rupesh Patil" />
                            <div className="client_desc">
                                <p className="namec">Rupesh Patil,</p>
                                <p className="named">Senior Manager MIS, Costing & Business Development</p>
                            </div>
                        </div>
                       
                        <img src={kkimg} alt="" className="kk_img" />
                    </div>
                    <p className="client_test">What I liked best about working with Sciative is their quest to understand the Customer’s need rather than pushing any of their products (which are great, by the way). The team is absolutely professional and the output work is very commendable. It has helped our business take key timely decisions.</p>
                </div>
                <div className="text-center client_det_para">
                        <p>If they could, even your brand can!</p>
                        <Link to='/retail/book-a-demo' className="yellow-button">Connect Now</Link>
                </div>
            </div>
        </section> */}
        <CapteraAchievements props={"brio_inside"} />
        <NavigationSection {...navigation_section_props_2} />
        
        <MarketPlaceIntegration  end_point={end_point} />
        <section className="retail_bg_cta">
          <div className="container" data-aos="new-animation">
            <div className="card ap_card">
              <p className="card_text">Let Your Numbers Also Speak for Your Brand Success</p>
              <Link to={`/retail/book-a-demo?navigation=${end_point}`}>
                <button className="yellow-button">Book a Demo Now</button>
              </Link>
            </div>
          </div>
        </section>
        <section className="qa_store">
          <div className="container" data-aos="new-animation">
            <h2 className="bigHead text-center mb-5">What all is in store for you ?</h2>
            <div className="row">
            <div className="col-md-4">
                <InnerCard headImg={bgautomated} pageheading="Automated Price Management" pagelink={AllRoutes.automatedpricingpage} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bgunified} pageheading="Unified Pricing Engine" pagelink={AllRoutes.unifiedpricingpage} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bggoal} pageheading="Goal Driven Pricing" pagelink={AllRoutes.goalbasedpage} />

              </div>
              {/* col-4 end */}
            </div>
          </div>
        </section>
       

        <section className="form-section">
          <div className="container" data-aos="new-animation">
            <div className="text-container">
              <h2 className="bigHead mb-5">Curious to know more about us!</h2>
              <p>Explore more about how our pricing experts  can scale your revenue to soaring heights just by pricing right.</p>
            </div>
            <div className="form-container">
              <Form {...formProps} />
            </div>
          </div>
        </section>
      </HelmetProvider>
    </>
  )
}
export default MarketPricing