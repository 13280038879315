import Footer from "../common/footer";
import Header from "../common/header";
import Ticker from "../common/ticker";
import { Button } from 'react-bootstrap';
import AllRoutes from "../../AllRoutes";
import InnerCard from "../retail/innercard";
import img1 from "../../assets/img/retail/electronics/automated_pricing.png";
import img2 from "../../assets/img/retail/electronics/demand_and_price_forecasting.png";
import img3 from "../../assets/img/retail/electronics/competition_intelligence.png";
import bggoal from "../../assets/img/retail/fashion-apparel/2.png";
import bgunified from "../../assets/img/retail/health-wellness-beauty/digital_shelf.png";
import bgcomp from "../../assets/img/retail/food-consumbales/competition-intelligence.png";
import { GlobalUrl, GlobalImg } from "../../global";

import { Tab, Col, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Recoginition from "../retail/recognition";
import CapteraAchievements from "../travel/capterra-achievvement";
import RetailHeader from "../retail/retailHeader";
import RetailClient from "../retail/retailCLient";
import AOS from "aos";
import { useEffect, useState } from "react";
import "aos/dist/aos.css";
import QAScore from "../retail/QAScore";
import InnerHeroRetail from "../retail/innerHeroRetail";
import RetailInsideNumber from "../retail/retailInsideNumber";
import RetailBenefits from "../retail/retailBenefits";
import MarketPlaceIntegration from "../retail/marketPlaceIntegration";
import RetailTestimonial from "../retail/retailTestimonial";
import RetailInsideNumsix from "../retail/retailInsideNumSix";
import Form from "../Forms/Form";
import { toast } from "react-toastify";
import { Helmet, HelmetProvider } from "react-helmet-async";
import BenefitSection from "../retail/BenefitSection";
import NavigationSection from "../retail/NavigationSection";
const Electronics = ({ }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])
  const end_point = "electronics";

  const qa_list = [
    "Have you experienced challenges in adjusting prices quickly in response to competitor pricing changes?",
    "Do you find it difficult to track and monitor pricing data across multiple products and categories?",
    "Have you missed opportunities to optimize prices based on real-time market demand and trends?",
    "Is it challenging to ensure pricing consistency across different sales channels and locations?",
  ];

  const formProps = {
    template: 4,
    form_name: 'retail-inside-pages',
    fields: [
      { name: 'name', required: true },
      { name: 'email', required: true },
      { name: 'company', required: true },
      { name: 'phone', required: true },
      { name: 'message', required: true },
    ],
    recipients_group_id: 1,
    handleApiResponse: (res) => {
      toast.dismiss();
      if (res.status == "success"){
        toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
      } else {
        toast.error(res.data)
    }
    }
  }
  const navigation_props = {
    id: "automated_pricing",
    heading: "Benefits of Using BRIO",
    tabs:[
      {
        title: "Automated Pricing",
        desc: "Switch from manual and intuitive pricing to target pricing, and see how it elevates your revenue streams by improving your bottom line revenue by 21%.",
        img: img1 ,
        alt: "Automated Pricing Image" 
      },
      {
        title: "Demand and Price Forecasting",
        desc: "Forecast your future customer demand with 94% accuracy, taking into account your inventory status to boost your operational efficiency by 30%.",
        img: img2 ,
        alt: "Demand and Price Forecasting" 
      },
      {
        title: "Competition Intelligence",
        desc: "Achieve 99% product matching accuracy with near-real-time data refresh rates. Our solution keeps you informed about your competitors’ pricing and identifies opportunities.",
        img: img3 ,
        alt: "Competition Intelligence" 
      },
     

    ]
  }
  const number_props = {
    id: "num_prop",
    heading: "We Let Numbers Do the Talking",
    tabs:[
      {
        num: "30%",
        desc: "Improvement in operational efficiency",
      },
      {
        num: "38%",
        desc: "Improvement in sales with scientific and timely decision",
      },
      {
        num: "37%",
        desc: "Improvement in markdown realization",
      },
      {
        num: "94%",
        desc: "Accurate demand forecasting",
      },
      {
        num: "65%",
        desc: "Improvement in response to market changes",
      },
      {
        num: "50%",
        desc: "Reduction in stock-outs through improved inventory management",
      },
     
     

    ]
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Automated Pricing Tool for Electronics Industry | Sciative</title>
          <link rel="canonical" href={`${GlobalUrl}${AllRoutes.electronics}`} />
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="csrf_token" content="" />
          <meta property="type" content="website" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="_token" content="" />
          <meta name="robots" content="noodp" />
          <meta name='description' content="Our AI automated pricing tool utilizes advanced retail dynamic pricing strategies and pricing intelligence to tailor prices for electronics in real-time." />
          <meta property="og:locale" content="en_US" />
          <meta property="og:title" content="Automated Pricing Tool for Electronics Industry | Sciative" />
          <meta property="og:description" content="Our AI automated pricing tool utilizes advanced retail dynamic pricing strategies and pricing intelligence to tailor prices for electronics in real-time." />
         
          <meta property="og:hashtag" content="#ai" />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:url" content={`${GlobalUrl}${AllRoutes.electronics}`} />
          <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
          <meta property="og:image"  content={GlobalImg} />
          <meta property="og:type" content="website" />
          <script type="application/ld+json">
              {`
              {
                "@context": "https://schema.org/", 
                "@type": "BreadcrumbList", 
                "itemListElement": [{
                  "@type": "ListItem", 
                  "position": 1, 
                  "name": "Home",
                  "item": "https://sciative.com"  
                },{
                  "@type": "ListItem", 
                  "position": 2, 
                  "name": "Retail",
                  "item": "https://sciative.com/retail"  
                },{
                  "@type": "ListItem", 
                  "position": 3, 
                  "name": "solution",
                  "item": "https://sciative.com/retail/electronics"  
                }]
              }
               
              `}
            </script>
        </Helmet>
        <InnerHeroRetail end_point={end_point} page_name="electronicsindustry" page_heading="Tailored Solutions for the Electronics Industry" page_desc1="Science-Backed Automated Pricing: Boost Revenue & Protect Margins Across Your Portfolio" button_name="See How Automation Works" />
       
       <NavigationSection {...navigation_props} />
        <RetailClient />



        <section className="client_det inside_det">
          <div className="container" data-aos="new-animation">
            <h2 className="bigHead mb-5">We Deliver, Our Customer's Speak </h2>
            <div className="card">

              <p className="client_test">Since implementing BRIO's automated pricing software and competition tracking features, our electronics retail business has experienced a remarkable transformation. The software's ability of automated pricing based on market trends and competitor actions has resulted in a 25% increase in revenue. We have seen a noticeable improvement in our competitiveness, with the software enabling us to stay agile and responsive to market changes. Additionally, our profit margins have increased by 15%, thanks to the optimized pricing strategies suggested by BRIO. Overall, BRIO has truly revolutionised our pricing strategies, and we are thrilled with the results it has delivered for our business.</p>
              <div className="flex_card">
                <div className="card_1">
                  {/* <img src={clientimg} alt="Rupesh Patil" /> */}
                  <div className="client_desc">
                    <p className="namec">Private Label Electronics Brand</p>
                    <p className="named">Asia</p>
                  </div>
                </div>

                {/* <img src={kkimg} alt="" className="kk_img" /> */}
              </div>
            </div>
            <div className="text-center mt-5">
              <p className="bold">Our experts are ready to help you in boosting your profit margins now!</p>
              <Link to={`/retail/book-a-demo?navigation=${end_point}`}>
                <button class="yellow-button">Book your slot!</button>
              </Link>
            </div>
          </div>
        </section>
        <RetailInsideNumsix end_point={end_point} num_prop={number_props} />
        {/* <RetailBenefits /> */}
        <CapteraAchievements props={"brio_inside"} />

        <MarketPlaceIntegration  end_point={end_point} />
        <section className="qa_store">
          <div className="container"  data-aos="new-animation">
            <h2 className="bigHead text-center mb-5">What all is in store for you ?</h2>
            <div className="row">
              <div className="col-md-4">
                <InnerCard headImg={bgunified} pageheading="Health, Wellness & Beauty" pagelink={AllRoutes.hwb} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bgcomp} pageheading="Food & Consumables" pagelink={AllRoutes.food} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bggoal} pageheading="Fashion & Apparels" pagelink={AllRoutes.fashion} />

              </div>
              {/* col-4 end */}
            </div>
          </div>
        </section>

        <section className="form-section">
          <div className="container" data-aos="new-animation">
            <div className="text-container">
              <h2 className="bigHead mb-5">Curious to know more about us!</h2>
              <p>Explore more about how our pricing experts  can scale your revenue to soaring heights just by pricing right.</p>
            </div>
            <div className="form-container">
              <Form {...formProps} />
            </div>
          </div>
        </section>

      </HelmetProvider>


    </>
  )
}
export default Electronics;