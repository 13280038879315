import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { GlobalUrl, GlobalImg } from "../../global";
import AllRoutes from '../../AllRoutes';
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ThemeProvider } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from 'react-router-dom';
import rightyellow from '../../assets/img/right-arrow-yellow.png';
import { toast } from 'react-toastify';
import theme from '../Themes/SciativeTheme';
import axios from 'axios';

// const theme = createTheme({
//     palette: {
//         primary: {
//             main: '#fff'
//         }
//     }
// });

const ProfitCalcPage = () => {

    const steps = ['Select Pricing Type', 'Fill in your Details', 'Tell us about your Business', 'Profit Report'];

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    //Step 1
    const [pricingType, setPricingType] = useState('');

    //Step 2
    const [name, setName] = useState('');
    const [nameValid, setNameValid] = useState(true);
    const [phone, setPhone] = useState('');
    const [phoneValid, setPhoneValid] = useState(true);
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const [numBuses, setNumBuses] = useState(null);

    //Step 3
    const [source, setSource] = useState('');
    const [distance, setDistance] = useState(null);
    const [busType, setBusType] = useState('');
    const [dieselCharges, setDieselCharges] = useState('');
    const [commission, setCommission] = useState('');
    const [salary, setSalary] = useState('');
    const [permitCharges, setPermitCharges] = useState('');
    const [avgSeatPrice, setAvgSeatPrice] = useState('');
    const [destination, setDestination] = useState('');
    const [toll, setToll] = useState('');
    const [mileage, setMileage] = useState('');
    const [numSeats, setNumSeats] = useState('');
    const [marketingExpense, setMarketingExpense] = useState('');
    const [emi, setEMI] = useState('');
    const [misc, setMisc] = useState('');

    //Step 4
    const [rows, setRows] = useState([]);

    const navigate = useNavigate();

    const isStepOptional = (step) => {
        return false;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const calculatePrices = async () => {
        try {
            const response = await axios.post(`/calculate_profit`,
                {
                    pricingType, name, phone, email, numBuses, source, distance, busType, dieselCharges, commission, salary, permitCharges,
                    avgSeatPrice, destination, toll, mileage, numSeats, marketingExpense, emi, misc
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
            if (response.status === 200 && response.data !== undefined) {
                let temp = []
                response.data.forEach(item => temp.push([item['name'], item['55'], item['65'], item['75'], item['85'], item['95']]))
                setRows(temp)
            }

        } catch (err) {
            console.log(err)
        }
    }

    const handleNext = () => {
        if (activeStep === 0) {
            if (!pricingType){
                toast.error('Please select a value!');
                return;
            };

            if (pricingType !== 'ai-dynamic') {
                setActiveStep(1)
            } else {
                navigate('/travel/book-a-demo')
            }

        }
        if (activeStep === 1) {
            if (!name || !nameValid || !email || !emailValid || !phone || !phoneValid || !numBuses) {
                toast.error('Please enter all values!');
                return;
            }
            setActiveStep(2)
        }
        if (activeStep === 2) {
            if (!source || !distance || !busType || !dieselCharges || !commission || !salary || !permitCharges || !avgSeatPrice || !destination || !toll || !mileage || !numSeats || !marketingExpense || !emi || !misc) {
                toast.error('Please enter all values!');
                return;
            }
            if(
                parseFloat(distance) <0 ||
                parseFloat(dieselCharges) <0 ||
                parseFloat(commission) <0 ||
                parseFloat(salary) <0 ||
                parseFloat(permitCharges) <0 ||
                parseFloat(avgSeatPrice) <0 ||
                parseFloat(toll) <0 ||
                parseFloat(mileage) <0 ||
                parseFloat(numSeats) <0 ||
                parseFloat(marketingExpense) <0 ||
                parseFloat(emi) <0 ||
                parseFloat(misc) <0
            ){
                toast.error('Values cannot be negative!');
                return;
            }
            setActiveStep(3)
            calculatePrices()
        }
        if (activeStep === 3){
            cleanup()
            setActiveStep(0)
        }
    };

    const cleanup = () => {
        setPricingType('')
        setName('')
        setNameValid(true)
        setPhone('')
        setPhoneValid(true)
        setEmail('')
        setEmailValid(true)
        setNumBuses(null)
        setSource('')
        setDistance('')
        setBusType('')
        setDieselCharges('')
        setCommission('')
        setSalary('')
        setPermitCharges('')
        setAvgSeatPrice('')
        setDestination('')
        setToll('')
        setMileage('')
        setNumSeats('')
        setMarketingExpense('');
        setEMI('')
        setMisc('')
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const validateName = (name) => {
        if (name.match(/[a-zA-Z]/g) !== null && name.match(/[a-zA-Z]/g).length >= 3) return true
        return false
    }
    const validateEmail = (email) => {
        return email.match(
            /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]{2,}\.[a-zA-Z]{2,4}$/
        );
    }
    const validatePhone = (phone) => {
        return phone.match(/^[0-9+]+$/) 
        && !phone.match(/^(.)\1+$/) 
        && phone.length === 10 
        && phone !== '1234567890'
        && ['7', '8', '9'].includes(phone[0])
    }

    useEffect(() => {
        if (name && !validateName(name)) {
            setNameValid(false)
        } else {
            setNameValid(true)
        }
    }, [name])
    useEffect(() => {
        if (email && !validateEmail(email)) {
            setEmailValid(false)
        } else {
            setEmailValid(true)
        }
    }, [email])
    useEffect(() => {
        if (phone && !validatePhone(phone)) {
            setPhoneValid(false)
        } else {
            setPhoneValid(true)
        }
    }, [phone])

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Sciative's Bus Industry Profit Calculator | Are you profitable or not? </title>
                    <link rel="canonical" href={`${GlobalUrl}${AllRoutes.ProfitCalculatorPage}`} />
                    <meta charset="utf-8" />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <meta name="csrf_token" content="" />
                    <meta property="type" content="website" />
                    <meta name="theme-color" content="#ffffff" />
                    <meta name="_token" content="" />
                    <meta name="robots" content="noodp" />
                    <meta name='description' content="Estimate your profit generation capacity with Sciative's industry agnostic profit calculator." />
                    <meta name='keywords' content="Travel, Viaje.ai, Bus, Dynamic Pricing, automate pricing,automated pricing tool,brand protection software,competitor price tracking software,demand forecasting software,discounting software" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:title" content="Sciative's Bus Industry Profit Calculator | Are you profitable or not? " />
                    <meta property="og:description" content="Estimate your profit generation capacity with Sciative's industry agnostic profit calculator." />
                    <meta property="og:hashtag" content="#ai" />
                    <meta content="image/*" property="og:image:type" />
                    <meta property="og:url" content={`${GlobalUrl}${AllRoutes.ProfitCalculatorPage}`} />
                    <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
                    <meta property="og:image"  content={GlobalImg} />
                    <meta property="og:type" content="website" />
                </Helmet>

                <ThemeProvider theme={theme}>
                    <section className=" about_banner profit_calulator" id='profit_calulator'>
                        <div className="container">
                            <h1 style={{fontSize: '2.5em'}} className='mb-3 bold'>Profit Calculator</h1>
                            <p className="about_para">Easily assess your business's profitability with our profit calculator. Instantly calculate profit margins and gain clarity on your financial performance. Make informed decisions and drive growth with precision, powered by our automated tool.</p>


                            <Box className='mt-5 profit-calc-section' sx={{ width: '100%' }}>
                                <Stepper className='mb-4' activeStep={activeStep}>
                                    {steps.map((label, index) => {
                                        const stepProps: { completed?: boolean } = {};
                                        const labelProps: {
                                            optional?: React.ReactNode;
                                        } = {};
                                        if (isStepOptional(index)) {
                                            labelProps.optional = (
                                                <Typography variant="caption">Optional</Typography>
                                            );
                                        }
                                        if (isStepSkipped(index)) {
                                            stepProps.completed = false;
                                        }
                                        return (
                                            <Step key={label} {...stepProps}>
                                                <StepLabel {...labelProps}>{label}</StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                                {activeStep === steps.length ? (
                                    <React.Fragment>
                                        <Typography sx={{ mt: 2, mb: 1 }}>
                                            All steps completed - you&apos;re finished
                                        </Typography>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                            <Box sx={{ flex: '1 1 auto' }} />
                                            <Button onClick={handleReset}>Reset</Button>
                                        </Box>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Typography sx={{ mt: 2, mb: 1 }}>{steps[activeStep]}</Typography>

                                        {
                                            activeStep === 0 ?
                                                <div>
                                                    <div className='d-flex flex-column'>
                                                        <div className='d-flex align-items-center p-2'>
                                                            <Checkbox checked={pricingType === 'standard'} onChange={(evt) => setPricingType('standard')} />
                                                            <label className='medium-2 thin'>Standard Pricing</label>
                                                        </div>
                                                        {/* <div className='d-flex align-items-center p-2'>
                                                            <Checkbox checked={pricingType === 'dynamic'} disabled />
                                                            <label className='medium-2 thin'>Dynamic Pricing (Coming soon)</label>
                                                        </div> */}
                                                        <div className='d-flex align-items-center p-2'>
                                                            <Checkbox checked={pricingType === 'ai-dynamic'} onChange={(evt) => setPricingType('ai-dynamic')} />
                                                            <label className='medium-2 thin'>AI Dynamic Pricing</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }

                                        {
                                            activeStep === 1 ?
                                                <div className='d-flex flex-column'>
                                                    <input
                                                        error={!nameValid}
                                                        className={`p-2 my-2 w-50 ${nameValid ? 'border-white' : 'border-red'}`}
                                                        placeholder='Operator Name*'
                                                        label="Operator Name*"
                                                        value={name}
                                                        onChange={(evt) => setName(evt.target.value)}
                                                        helperText={!nameValid ? "Invalid Operator Name!" : ''}
                                                    />
                                                    {!nameValid ? <small className='text-danger mb-1'>Invalid Name!</small> : null}
                                                    <input
                                                        error={!emailValid}
                                                        className={`p-2 my-2 w-50 ${emailValid ? 'border-white' : 'border-red'}`}
                                                        placeholder='Enter Email Address*'
                                                        label="Email Adress*"
                                                        value={email}
                                                        onChange={(evt) => setEmail(evt.target.value)}
                                                        helperText={!emailValid ? "Invalid email address!" : ''}
                                                    />
                                                    {!emailValid ? <small className='text-danger mb-1'>Invalid Email Address!</small> : null}
                                                    <input
                                                        error={!phoneValid}
                                                        className={`p-2 my-2 w-50 ${phoneValid ? 'border-white' : 'border-red'}`}
                                                        placeholder='Mobile Number*'
                                                        label="Mobile number*"
                                                        value={phone}
                                                        onChange={(evt) => setPhone(evt.target.value)}
                                                        helperText={!phoneValid ? "Invalid Mobile number!" : ''}
                                                    />
                                                    {!phoneValid ? <small className='text-danger mb-1'>Invalid Mobile Number!</small> : null}
                                                    <span className='bold mt-2' >Number of Buses*</span>
                                                    <div className='d-flex flex-wrap'>

                                                        <div className='mx-2 d-flex p-1 align-items-center'>
                                                            <Checkbox checked={numBuses === '1-10'} onChange={() => setNumBuses('1-10')} />
                                                            <label className='medium-2 thin'>1-10</label>
                                                        </div>
                                                        <div className='mx-2 d-flex p-1 align-items-center'>
                                                            <Checkbox checked={numBuses === '10-25'} onChange={() => setNumBuses('10-25')} />
                                                            <label className='medium-2 thin'>10-25</label>
                                                        </div>
                                                        <div className='mx-2 d-flex p-1 align-items-center'>
                                                            <Checkbox checked={numBuses === '25-40'} onChange={() => setNumBuses('25-40')} />
                                                            <label className='medium-2 thin'>25-40</label>
                                                        </div>
                                                        <div className='mx-2 d-flex p-1 align-items-center'>
                                                            <Checkbox checked={numBuses === '40-60'} onChange={() => setNumBuses('40-60')} />
                                                            <label className='medium-2 thin'>40-60</label>
                                                        </div>
                                                        <div className='mx-2 d-flex p-1 align-items-center'>
                                                            <Checkbox checked={numBuses === '60-80'} onChange={() => setNumBuses('60-80')} />
                                                            <label className='medium-2 thin'>60-80</label>
                                                        </div>
                                                        <div className='mx-2 d-flex p-1 align-items-center'>
                                                            <Checkbox checked={numBuses === '80-100'} onChange={() => setNumBuses('80-100')} />
                                                            <label className='medium-2 thin'>80-100</label>
                                                        </div>
                                                        <div className='mx-2 d-flex p-1 align-items-center'>
                                                            <Checkbox checked={numBuses === '100+'} onChange={() => setNumBuses('100+')} />
                                                            <label className='medium-2 thin'>100+</label>
                                                        </div>

                                                    </div>
                                                </div>
                                                :
                                                null
                                        }

                                        {
                                            activeStep === 2 ?
                                                <div className='w-100 row'>
                                                    <div className='col-6 p-3'>
                                                        <input
                                                            className='w-100 my-2 border-white p-2'
                                                            placeholder='Source*'
                                                            label="Source*"
                                                            value={source}
                                                            onChange={(evt) => setSource(evt.target.value)}
                                                            size="small"
                                                        />
                                                        <input
                                                            className='w-100 my-2 border-white p-2'
                                                            placeholder='Distance*'
                                                            label="Distance*"
                                                            type='number'
                                                            value={distance}
                                                            onChange={(evt) => setDistance(evt.target.value)}
                                                            size="small"
                                                        />
                                                        <select
                                                            className='border-white p-2 my-2 w-100'
                                                            value={busType}
                                                            label="Bus Type*"
                                                            onChange={(evt) => setBusType(evt.target.value)}
                                                        >
                                                            <option selected hidden>Select Bus Type</option>
                                                            <option value='ac-sleeper'>AC Sleeper</option>
                                                            <option value='non-ac-sleeper'>Non-AC Sleeper</option>
                                                            <option value='ac-seater'>AC Seater</option>
                                                            <option value='non-ac-seater'>Non-AC Seater</option>
                                                            <option value='ac-seater-sleeper'>AC Seater/Sleeper</option>
                                                            <option value='non-ac-seater-sleeper'>Non-AC Seater/Sleeper</option>
                                                        </select>
                                                        <input
                                                            value={dieselCharges}
                                                            onChange={(evt) => setDieselCharges(evt.target.value)}
                                                            label="Diesel Charges (per ltr)*"
                                                            type='number'
                                                            className='w-100 my-2 border-white p-2'
                                                            placeholder='Diesel Charges (per ltr)*'
                                                            size='small'
                                                        />
                                                        <input
                                                            value={commission}
                                                            onChange={(evt) => setCommission(evt.target.value)}
                                                            label="% Sales Commission (OTA like Redbus)*"
                                                            type='number'
                                                            className='w-100 my-2 border-white p-2'
                                                            placeholder='% Sales Commission (OTA like Redbus)*'
                                                            size='small'
                                                            max='100'
                                                        />
                                                        <input
                                                            value={salary}
                                                            onChange={(evt) => setSalary(evt.target.value)}
                                                            label="Driver + Conductor Salary (per month)*"
                                                            type='number'
                                                            className='w-100 my-2 border-white p-2'
                                                            placeholder='Driver + Conductor Salary (per month)*'
                                                            size='small'
                                                        />
                                                        <input
                                                            value={permitCharges}
                                                            onChange={(evt) => setPermitCharges(evt.target.value)}
                                                            label="Quarterly Permit Charges*"
                                                            type='number'
                                                            className='w-100 my-2 border-white p-2'
                                                            placeholder='Quarterly Permit Charges*'
                                                            size='small'
                                                        />
                                                        <input
                                                            value={avgSeatPrice}
                                                            onChange={(evt) => setAvgSeatPrice(evt.target.value)}
                                                            label="Average Seat Price*"
                                                            type='number'
                                                            className='w-100 my-2 border-white p-2'
                                                            placeholder='Average Seat Price*'
                                                            size='small'
                                                        />
                                                    </div>
                                                    <div className='col-6 p-3'>
                                                        <input
                                                            value={destination}
                                                            onChange={(evt) => setDestination(evt.target.value)}
                                                            label="Destination*"
                                                            className='w-100 my-2 border-white p-2'
                                                            placeholder='Destination*'
                                                            size='small'
                                                        />
                                                        <input
                                                            value={toll}
                                                            onChange={(evt) => setToll(evt.target.value)}
                                                            label="Toll*"
                                                            type='number'
                                                            className='w-100 my-2 border-white p-2'
                                                            placeholder='Toll*'
                                                            size='small'
                                                        />
                                                        <input
                                                            value={mileage}
                                                            onChange={(evt) => setMileage(evt.target.value)}
                                                            label="Mileage (km/ltr)*"
                                                            type='number'
                                                            className='w-100 my-2 border-white p-2'
                                                            placeholder='Mileage (km/ltr)*'
                                                            size='small'
                                                        />
                                                        <input
                                                            value={numSeats}
                                                            onChange={(evt) => setNumSeats(evt.target.value)}
                                                            label="Number of Seats*"
                                                            type='number'
                                                            className='w-100 my-2 border-white p-2'
                                                            placeholder='Number of Seats*'
                                                            size='small'
                                                        />
                                                        <input
                                                            value={marketingExpense}
                                                            onChange={(evt) => setMarketingExpense(evt.target.value)}
                                                            label="Marketing Expense (per seat)*"
                                                            type='number'
                                                            className='w-100 my-2 border-white p-2'
                                                            placeholder='Marketing Expense (per seat)*'
                                                            size='small'
                                                        />
                                                        <input
                                                            value={emi}
                                                            onChange={(evt) => setEMI(evt.target.value)}
                                                            label="Bus Monthly EMI*"
                                                            type='number'
                                                            className='w-100 my-2 border-white p-2'
                                                            placeholder='Bus Monthly EMI*'
                                                            size='small'
                                                        />
                                                        <input
                                                            value={misc}
                                                            onChange={(evt) => setMisc(evt.target.value)}
                                                            label="Miscallaneous Charges*"
                                                            type='number'
                                                            className='w-100 my-2 border-white p-2'
                                                            placeholder='Miscallaneous Charges*'
                                                            size='small'
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }

                                        {
                                            activeStep === 3 ?
                                                <div style={{maxWidth: '100%', overflowX: 'auto'}}>
                                                    <table className="w-100 profit-table">
                                                        <thead className='p-3'>
                                                            <tr className='text-center'>
                                                                <th scope="col">Occupancy</th>
                                                                <th scope="col">55%</th>
                                                                <th scope="col">65%</th>
                                                                <th scope="col">75%</th>
                                                                <th scope="col">85%</th>
                                                                <th scope="col">95%</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                rows.map((item, key) => (
                                                                    <tr className={item[0] === 'Net Revenues' || item[0] === 'Gross Profit' || item[0] === 'Net Profit' ? 'bg-yellow text-black text-center' : 'text-center'} key={key}>
                                                                        <td className={item[0] === 'Net Revenues' || item[0] === 'Gross Profit' || item[0] === 'Net Profit' ? 'bold' : ''}>{item[0]}</td>
                                                                        <td className={item[0] === 'Net Revenues' || item[0] === 'Gross Profit' || item[0] === 'Net Profit' ? 'bold' : ''}>₹{item[1].toLocaleString('en-IN')}</td>
                                                                        <td className={item[0] === 'Net Revenues' || item[0] === 'Gross Profit' || item[0] === 'Net Profit' ? 'bold' : ''}>₹{item[2].toLocaleString('en-IN')}</td>
                                                                        <td className={item[0] === 'Net Revenues' || item[0] === 'Gross Profit' || item[0] === 'Net Profit' ? 'bold' : ''}>₹{item[3].toLocaleString('en-IN')}</td>
                                                                        <td className={item[0] === 'Net Revenues' || item[0] === 'Gross Profit' || item[0] === 'Net Profit' ? 'bold' : ''}>₹{item[4].toLocaleString('en-IN')}</td>
                                                                        <td className={item[0] === 'Net Revenues' || item[0] === 'Gross Profit' || item[0] === 'Net Profit' ? 'bold' : ''}>₹{item[5].toLocaleString('en-IN')}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                :
                                                null
                                        }

                                        <div className='w-100 d-flex justify-content-center'>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                                <button
                                                    className='text-white mx-2 btn-outline'
                                                    color="inherit"
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                    sx={{ mr: 1 }}
                                                >
                                                    Back
                                                </button>
                                                <Box sx={{ flex: '1 1 auto' }} />
                                                <button onClick={handleNext} className='mx-2 btn-yellow'>
                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                </button>
                                            </Box>
                                        </div>
                                    </React.Fragment>
                                )}
                            </Box>



                        </div>
                    </section>
                </ThemeProvider>
            </HelmetProvider>
        </>
    )
};

export default ProfitCalcPage;