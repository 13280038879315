import { useEffect, useState } from 'react';
import Footer from "../common/footer";
import Header from "../common/header";
import Ticker from "../common/ticker";
import '../../assets/css/main.scss';
import brioLogo from '../../assets/img/brio.png'
import rightarrow from '../../assets/img/right-arrow.png';
import { GlobalUrl, GlobalImg } from "../../global";
import retailvideo from '../../assets/videos/retail_video.mp4';

import { Tab, Col, Nav, Navbar, Container, NavDropdown, Button, Row } from "react-bootstrap";
import InnerCard from "../retail/innercard";
import MarketPlaceIntegration from "../retail/marketPlaceIntegration";
import ClientSlider from "../retail/clientSlider";
import Leadership from "../retail/leadership";
import FounderVideo from "../retail/founderVideo";
import Recoginition from "../retail/recognition";
import ProductNumber from "../retail/productnumber";
import RaTalkSingle from "../retail/ratalksingle";
import TestimonialSlider from "../retail/testimonialslider";
import { Helmet, HelmetProvider } from "react-helmet-async";
import HotelHeader from "../hotels/hotelHeader";
import HotelHeaderMobile from "../hotels/hotelHeaderMobile";
import RetailClient from "../retail/retailCLient";
import RetailNumbers from "../retail/retailNumbers";
import CapteraAchievements from "../travel/capterra-achievvement";
import Testimonial from "../hotels/Testimonial";
import RetailDatapoint from "../retail/retaiDatapoint";
import img10 from "../../assets/img/retail/automated-pricing/10.jpg";
import img11 from "../../assets/img/retail/automated-pricing/11.jpg";
import img12 from "../../assets/img/retail/automated-pricing/12.jpg";
import AutomatedPricingTab from "../retail/automatedPricingTab";
import MarketIntelligenceTab from "../retail/marketIntelligenceTab";
import SalesInventoryTab from "../retail/salesInventoryTab";
import DiscountManagementTab from "../retail/discountManagement";
import HotelInsideNumber from "../hotels/hotelInsideNumber";
import HotelIntegration from "../hotels/hotelIntegration";
import HotelMainSlider from "../hotels/mainSlider";
import convicon from "../../assets/img/hotels/conversation-icon.png";
import profiticon from "../../assets/img/hotels/profit-icon.png";
import imguni from "../../assets/img/retail/automated-pricing/unified_price.png";
import imgoth from "../../assets/img/retail/other-drivers/keep-tab-on-sales.png";
import automationdahboard from "../../assets/img/retail/automated-pricing/dsahboard.png";
import marketinteldahboard from "../../assets/img/retail/market-intelligence/dashboard.png";
import customerdatadahboard from "../../assets/img/retail/customer-data/dashboard.png";
import digitalshelfdahboard from "../../assets/img/retail/digital-shlef/dashboard.png";
import discountmgmt from "../../assets/img/retail/discount-marketing/dashboard.png";
import bggoal from "../../assets/img/retail/goalbased-pricing/goal-based-pricing.jpg";
import bgunified from "../../assets/img/retail/unified-pricing/unified-pricing.jpg";
import bgcomp from "../../assets/img/retail/competition-intelligence/assortment_availability.png";
import bgautomated from "../../assets/img/retail/automated-pricing/automated-pricing.png";
import hotelbgmain from "../../assets/img/hotels/hotel-bg-main.jpg";

import Form from "../Forms/Form";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import AllRoutes from "../../AllRoutes";
import HotelBgcta from "../hotels/hotelbgcta";
import NavigationBody from '../../components/retail/NavigationBody';
import AOS from "aos";
import "aos/dist/aos.css";

const Hotels = ({ }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])
  const [showHeader1, setShowHeader1] = useState(false);
  const [showHeader2, setShowHeader2] = useState(false);
  const formProps = {
    template: 4,
    form_name: 'hotel-main-page',
    fields: [
      { name: 'name', required: true },
      { name: 'email', required: true },
      { name: 'company', required: true },
      { name: 'phone', required: true },
      { name: 'message', required: true },
    ],
    recipients_group_id: 5,
    handleApiResponse: (res) => {
      toast.dismiss();
      if (res.status == "success"){
        toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
      } else {
        toast.error(res.data)
      }
    }
  }

  const contactExpertForm = {
    template: 5,
    form_name: 'contact-expert-form',
    fields: [
        { name: 'name', required: true },
        { name: 'email', required: true },
        { name: 'phone', required: true },
        { name: 'company', required: true },
        { name: 'message', required: true },
    ],
    recipients_group_id: 1,
    handleApiResponse: (res) => {
        toast.dismiss();
        if (res.status == "success"){
            toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
        } else {
            toast.error(res.data)
        }
    }
}



  const end_point = "main";

  function scrollFunction() {
    // console.log("here")
    var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll >= 80 && window.innerWidth >= 600) {
      setShowHeader2(false);
      setShowHeader1(true);
      // console.log("in if")
    }
    else if (winScroll >= 80 && window.innerWidth < 600) {
      setShowHeader1(false)
      setShowHeader2(true)
      // console.log("in elif")
    }
    else {
      setShowHeader1(false)
      setShowHeader2(false)
      // console.log("in else")
    }
  }

  useEffect(() => {
    document.addEventListener('wheel', function () { scrollFunction() });
    document.addEventListener('scroll', function () { scrollFunction() });
    // window.addEventListener('wheel', function () { scrollFunction() });
    // window.addEventListener('scroll', function () { scrollFunction() });
  }, [])

 

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Hotel Revenue Management System | Sciative</title>
          <link rel="canonical" href={`${GlobalUrl}${AllRoutes.retail}`} />
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="csrf_token" content="" />
          <meta property="type" content="website" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="_token" content="" />
          <meta name="robots" content="noodp" />
          <meta name='description' content="Boost revenue and enhance guest satisfaction with our AI Revenue Management System, tailored for the hospitality industry." />
          {/* <meta name='keywords' content="Travel, Viaje.ai, Bus, Dynamic Pricing, automate pricing,automated pricing tool,brand protection software,competitor price tracking software,demand forecasting software,discounting software" /> */}
          <meta property="og:locale" content="en_US" />
          <meta property="og:title" content="Hotel Revenue Management System | Sciative" />
          <meta property="og:description" content="Boost revenue and enhance guest satisfaction with our AI Revenue Management System, tailored for the hospitality industry." />
         
          <meta property="og:hashtag" content="#ai" />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:url" content={`${GlobalUrl}${AllRoutes.retail}`} />
          <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
          <meta property="og:image"  content={GlobalImg} />
          <meta property="og:type" content="website" />
        </Helmet>
        <div className='hotel_pages'>
        {/* <section className="this_div">
            <img src={img11} alt="Brio Logo" id="banner-logo" />
            </section> */}
        {
          showHeader1 ?
            <section style={{ padding: '0', borderBottom: '1px solid #fff', width: '100%', position: 'fixed', top: '43px', height: '4.5em', zIndex: 100 }} className='banner_retail main_banner bg-black'>
              <div className='container'>
                <HotelHeader end_point={end_point} />
              </div>
            </section>
            :
            showHeader2 ?
                <HotelHeaderMobile/>
              :
              null
        }

        <section className="banner_retail main_banner banner_2">
          <div className="container">
            {/* <img src={nuRetailLogo} alt="NuRetail Logo" id="banner-logo" /> */}
            <HotelHeader end_point={end_point} />
            <div className="row">
              <div className="col-md-7-5">
                <h1>AI Revenue Management System for Hotels</h1>
                <p>Integrate Our Scalable, Easy-to-Use, and Accurate Revenue Solution Today</p>
                <p className="text-yellow">Achieve an instant RevPAR growth with Zettaprice</p>
                <div>
                  <Link to={AllRoutes.hotelcontact}>
                    <Button className="yellow-button">Get Started Now</Button>
                  </Link>
                </div>
              </div>
              <div className="col-md-4-5 pe-0 ps-0 d-none d-lg-block">
                <Form {...contactExpertForm} />
              </div>
             
              <div className="mt-5 col-12 pe-0 ps-0 d-block d-lg-none">
                <Form {...contactExpertForm} />
              </div>
            </div>
            
            

          </div>
        </section>
        
        <section className='hotels_second'>
          <div className='container'>
            <div className='row' data-aos="new-animation">
              <div className="col-lg-5">
                  <h2 className='bigHead'>
                  Optimize Revenue with <br className=' d-none d-lg-block'/> Worlds's 1st Revenue <br className='d-none d-lg-block'/> Management System for Hotels
                  </h2>
                  <p>
                  Empower your revenue teams with Sciative's plug and play cloud hotel revenue management software, offering real-time pricing , forecasting, and demand analytics.
                  </p>
                  <Link to={AllRoutes.hotelcontact}>
                    <Button className="yellow-button">Explore Solutions</Button>
                  </Link>
              </div>
              <div className="col-lg-7">
                    <img src={hotelbgmain} alt="hotel image" />
              </div>
            </div>
          </div>

        </section>
        <HotelMainSlider />
        
        <HotelInsideNumber end_point={end_point} />
        <section className="hotel-cta" data-aos="new-animation">
              <div className="container" >
                {/* <h2 className="bigHead text-center">
                Explore the difference with Zettaprice
                </h2> */}
                <div className="text-center">
                  <Link to={AllRoutes.hotelcontact}>
                      <button className='yellow-button'>Maximize Revenue Now</button>
                  </Link>
                 </div>
              </div>
            </section>
        <CapteraAchievements props={"hotel_bg_1"} />
       
        <HotelIntegration end_point={end_point} />
        <HotelBgcta props={"hotel_bg_main"} />
        <Testimonial />
        {/* <section className='hotel_section_2'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='card '>
                  <div className='row'>
                    <div className='col-md-9'>
                          <h3 className='bigHead'>Calculate Your<br />
                          Profitability</h3>

                          <a>Discover your Revenue Potential <span class="material-symbols-outlined">chevron_right</span></a>
                      </div>
                      <div className='col-md-3'>
                        <img src={profiticon} alt="Profit Icon" />
                      </div>
                  </div>
                  
                  
                  
                </div>
              </div>
              <div className='col-md-6'>
              <div className='card '>
                <div className='row'>
                      <div className='col-md-9'>
                        <h3 className='bigHead'>Get your Questions <br />
                        Answered</h3>

                        <a>Explore FAQs <span class="material-symbols-outlined">chevron_right</span></a>
                    </div>
                    <div className='col-md-3'>
                       <img src={convicon} alt="Conversation Icon" />
                    </div>
                </div>
                  
                  
                  
                </div>
            
              </div>

            </div>

          </div>
        </section> */}
        <Recoginition page_name="home_recog" />
     
       
       
        {/* <TestimonialSlider /> */}

        <section className="form-section retail_form">
          <div className="container">
            <div className="text-container">
              <h2 className="bigHead mb-5">Discover What Zettaprice Can Do for You</h2>
              <p>We're eager to understand your unique revenue goals and show you how Zettaprice can help you achieve them</p>
            </div>
            <div className="form-container">
              <Form {...formProps} />
            </div>
          </div>
        </section>
        </div>
      </HelmetProvider>
    </>
  );
}


export default Hotels;