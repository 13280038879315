import AllRoutes from "../../AllRoutes";
import InnerCard from "../retail/innercard";
import img1 from "../../assets/img/retail/automated-pricing/optimize_revenue_and_margin.png";
import img2 from "../../assets/img/retail/automated-pricing/gain_strategic_competitive_advantage.png";
import img3 from "../../assets/img/retail/automated-pricing/eliminate_manual_errors_and_guesswork.png";
import img4 from "../../assets/img/retail/automated-pricing/become_highly_scalable.png";
import bggoal from "../../assets/img/store/goal-driven-pricing.png";
import bgunified from "../../assets/img/store/unified-pricing.png";
import bgautomated from "../../assets/img/store/automated-pricing.png";

import { Link } from "react-router-dom";
import CapteraAchievements from "../travel/capterra-achievvement";
import RetailClient from "../retail/retailCLient";
import AOS from "aos";
import { useEffect, useState } from "react";
import "aos/dist/aos.css";
import InnerHeroRetail from "../retail/innerHeroRetail";
import RetailInsideNumber from "../retail/retailInsideNumber";
import RetailBenefits from "../retail/retailBenefits";
import MarketPlaceIntegration from "../retail/marketPlaceIntegration";
import RetailTestimonial from "../retail/retailTestimonial";
import Form from "../Forms/Form";
import { toast } from "react-toastify";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { GlobalUrl, GlobalImg } from "../../global";
import NavigationSection from "../retail/NavigationSection";
import BenefitSection from "../retail/BenefitSection";
import b1 from "../../assets/img/icon/integration.png";
import b2 from "../../assets/img/icon/Swift.png";
import b3 from "../../assets/img/icon/Customer.png";
import b4 from "../../assets/img/icon/Real-time.png";
import b5 from "../../assets/img/icon/Technology.png";
import b6 from "../../assets/img/icon/Customizations.png";
import ProblemSection from "../retail/ProblemSection";
import pimg1 from "../../assets/img/picon/1.png";
import pimg2 from "../../assets/img/picon/2.png";
import pimg3 from "../../assets/img/picon/3.png";
import pimg4 from "../../assets/img/picon/4.png";
import pimg5 from "../../assets/img/picon/5.png";
const AutomatedDp = ({ }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])
  const end_point = "automated-dynamic-pricing";

  const qa_list = [
    "Have you experienced challenges in adjusting prices quickly in response to competitor pricing changes?",
    "Do you find it difficult to track and monitor pricing data across multiple products and categories?",
    "Have you missed opportunities to optimize prices based on real-time market demand and trends?",
    "Is it challenging to ensure pricing consistency across different sales channels and locations?",
  ];

  const formProps = {
    template: 4,
    form_name: 'retail-inside-pages',
    fields: [
      { name: 'name', required: true },
      { name: 'email', required: true },
      { name: 'company', required: true },
      { name: 'phone', required: true },
      { name: 'message', required: true },
    ],
    recipients_group_id: 1,
    handleApiResponse: (res) => {
      toast.dismiss();
      if (res.status == "success"){
        toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
      } else {
        toast.error(res.data)
    }
    }

  }

  const navigation_section_props = {
    id: "automated_dynamic_pricing",
    heading: "Industry Problems That Our Automated Pricing Tool Solves",
    tabs: [
        {
          title: "Massive scale of data with millions of data driven decision points.",
          img: pimg1 ,
          alt: "Massive scale of data with millions of data driven decision points.",
          correcttitle: "BRIO tames data chaos! Millions of data-driven decisions optimized with AI for your retail strategy."
        },
        {
          title: "Large volume and variety of data with simultaneous multi criteria decisions.",
          img: pimg2,
          alt: "Large volume and variety of data with simultaneous multi criteria decisions.",
          correcttitle: "BRIO unlocks big data's power!  Make smart decisions for your optimal retail strategy."
        },
        {
          title: "Operational challenges of inventory and stock management.",
          img: pimg3,
          alt: "Operational challenges of inventory and stock management.",
          correcttitle: "BRIO streamlines operations, and boosts profits. Automates tasks and optimizes inventory with AI."
        },
        {
          title: "Time is money as pricing decisions have to be made quick, cost of delay can be fatal!",
         
          img: pimg4,
          alt: "Time is money as pricing decisions have to be made quick, cost of delay can be fatal!",
          correcttitle: "BRIO makes pricing a breeze! Real-time data insights maximize your profit in a flash."
        },
        {
          title: "Incomplete product attribute information leading to missing sales opportunity.",
         
          img: pimg5,
          alt: "Incomplete product attribute information leading to missing sales opportunity.",
          correcttitle: "BRIO completes your product data, maximizes sales. AI fills the gaps, driving informed pricing. "
        },
    ]
  }
  const benefit_props = {
    id: "automated_pricing",
    heading: "Benefits of Using BRIO",
    tabs:[
      {
        title: "Data Integration and Analysis",
        desc: "Plug and play APIs with zero integration for quick setups and rapid ROI.",
        img: b1 ,
        alt: "Data Integration and Analysis Image" 
      },
      {
        title: "Swift Adaptability to Market Changes",
        desc: "Pricing decisions have to be made quick,as the cost of delay can be fatal and hence  we real time data refresh rates and highest level of data accuracy.",
        img: b2 ,
        alt: "Swift Adaptability to Market Changes" 
      },
      {
        title: "Improved Customer Experience",
        desc: "Fair and competitive prices to the customers leading to increased loyalty and repeat business by 25%.",
        img: b3 ,
        alt: "Improved Customer Experience" 
      },
      {
        title: "Real-time Actionable Insights",
        desc: "Never miss out on the revenue leaks opportunity due to incorrect pricing of your inventory.",
        img: b4 ,
        alt: "Real-time Actionable Insights" 
      },
      {
        title: "Technology",
        desc: "Powered by state-of-the-art AI/ML technology, powerful retail datasets, and can automate your pricing strategies.",
        img: b5 ,
        alt: "Technology" 
      },
      {
        title: "Customizations",
        desc: "Our solution is flexible and customizable to fit the needs of any retail organization, no matter where they are.",
        img: b6 ,
        alt: "Customizations" 
      },

    ]
  }
  const navigation_section_props_2 = {
    id: "automated_dpricing",
    heading: "How BRIO's Advanced Pricing Solutions Drive Business Success",
    tabs: [
        {
          title: "Optimise Revenue and Margin",
          desc: "Our AI-driven pricing software is fast, accurate, and delivers pricing recommendations based on a simultaneous assessment of multiple decision criteria within seconds, ensuring a margin and revenue increase of at least 21%.",
          img: img1 ,
          alt: "Optimise Revenue and Margin" 
        },
        {
          title: "Gain Strategic Competitive Advantage",
          desc: "With high product matching accuracy and near real-time refresh data rates, our solution keeps you ahead of your competitors’ price movements and identifies opportunities they miss, thus enhancing product margins and boosting revenue.",
          img: img2,
          alt: "Gain Strategic Competitive Advantage"
        },
        {
          title: "Eliminate Manual Errors and Guesswork",
          desc: "Our automated pricing solutions minimise uncertainty in outcomes, thereby boosting revenue; they also provide additional value by freeing up employees to focus on other important tasks such as customer success and strategic planning.",
          img: img3,
          alt: "Eliminate Manual Errors and Guesswork"
        },
        {
          title: "Become Highly Scalable",
          desc: "Whether you operate a few stores or manage a global network, our automated systems effortlessly scale to meet the expanding needs of your retail business.",
          img: img4,
          alt: "Become Highly Scalable"
        }
    ]
  }
  return (
    <>

      <HelmetProvider>
        <Helmet>
          <title>Automated Pricing Company for Retail Industry| Sciative</title>
          <link rel="canonical" href={`${GlobalUrl}${AllRoutes.automated_dp}`} />
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="csrf_token" content="" />
          <meta property="type" content="website" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="_token" content="" />
          <meta name="robots" content="noodp" />
          <meta name='description' content="Revolutionize pricing in retail with real-time AI that optimizes margins, maximizes sales, boosts profitability and helps grow business. Connect now" />
         
          <meta property="og:locale" content="en_US" />
          <meta property="og:title" content="Automated Pricing Company for Retail Industry| Sciative" />
          <meta property="og:description" content="Revolutionize pricing in retail with real-time AI that optimizes margins, maximizes sales, boosts profitability and helps grow business. Connect now" />
         
          <meta property="og:hashtag" content="#ai" />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:url" content={`${GlobalUrl}${AllRoutes.automated_dp}`} />
          <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
          <meta property="og:image"  content={GlobalImg} />
          <meta property="og:type" content="website" />
          <script type="application/ld+json">
              {`
               {
                "@context": "https://schema.org/", 
                "@type": "BreadcrumbList", 
                "itemListElement": [{
                  "@type": "ListItem", 
                  "position": 1, 
                  "name": "Home",
                  "item": "https://sciative.com"  
                },{
                  "@type": "ListItem", 
                  "position": 2, 
                  "name": "Retail",
                  "item": "https://sciative.com/retail"  
                },{
                  "@type": "ListItem", 
                  "position": 3, 
                  "name": "solution",
                  "item": "https://sciative.com/retail/automated-dynamic-pricing"  
                }]
              }
              
                
              `}
            </script>
        </Helmet>

        <InnerHeroRetail end_point={end_point} page_name="automatedDp" page_heading="Time Saving With An Automated Pricing Tool" page_desc1="Get 22% Rise In Top Line Revenue With Our World Class" page_desc2="AI-Powered Pricing Engine" button_name="Try Our Automated Pricing Tool"/>
        <RetailClient />
        <ProblemSection {...navigation_section_props} />
  
    

        <section className="client_det inside_det">
          <div className="container" data-aos="new-animation">
            <h2 className="bigHead mb-5">We Deliver, Our Customers ‘ Speak </h2>
            <div className="card">

              <p className="client_test">Since implementing the automated pricing tool - BRIO, our retail business has seen remarkable results. The ability to swiftly adjust prices based on real-time market data on a real time basis has not only optimized our profitability but also enhanced our competitiveness in the global market. The team at  has truly revolutionised our pricing strategies, and we couldn't be more pleased with the outcomes.</p>
              <div className="flex_card">
                <div className="card_1">
                  {/* <img src={clientimg} alt="Rupesh Patil" /> */}
                  <div className="client_desc">
                    <p className="namec">Private Label Fashion Brand</p>
                    <p className="named">North America</p>
                  </div>
                </div>

                {/* <img src={kkimg} alt="" className="kk_img" /> */}
              </div>
            </div>
            <div className="text-center mt-5">
              <p className="bold">If they could, even your brand can!</p>
              <Link to={`/retail/book-a-demo?navigation=${end_point}`}>
                <button class="yellow-button">Connect now</button>
              </Link>
            </div>
          </div>
        </section>
        <RetailInsideNumber end_point={end_point} />
        {/* <RetailBenefits /> */}
       
        <BenefitSection {...benefit_props} />
    

        {/* <section className="client_det auto_price">
            <div className="container">
                <h2 className="bigHead mb-5">We Deliver, Our Customers ‘ Speak </h2>
                <div className="card">
                    <div className="flex_card">
                        <div className="card_1">
                            <img src={clientimg} alt="Rupesh Patil" />
                            <div className="client_desc">
                                <p className="namec">Rupesh Patil,</p>
                                <p className="named">Senior Manager MIS, Costing & Business Development</p>
                            </div>
                        </div>
                       
                        <img src={kkimg} alt="" className="kk_img" />
                    </div>
                    <p className="client_test">What I liked best about working with Sciative is their quest to understand the Customer’s need rather than pushing any of their products (which are great, by the way). The team is absolutely professional and the output work is very commendable. It has helped our business take key timely decisions.</p>
                </div>
                <div className="text-center client_det_para">
                        <p>If they could, even your brand can!</p>
                        <Link to='/retail/book-a-demo' className="yellow-button">Connect Now</Link>
                </div>
            </div>
        </section> */}
        <CapteraAchievements props={"brio_inside"} />
        <NavigationSection {...navigation_section_props_2} />
{/* 
        <section className="retail_bg_cta">
          <div className="container" data-aos="new-animation">
            <div className="card ap_card">
              <p className="card_text">Let Your Numbers Also Speak for Your Brand Success</p>
              <Link to={`/retail/book-a-demo?navigation=${end_point}`}>
                <button className="yellow-button">Book a Demo Now</button>
              </Link>
            </div>
          </div>
        </section> */}
          <MarketPlaceIntegration  end_point={end_point}/>
        <section className="qa_store">
          <div className="container" data-aos="new-animation">
            <h2 className="bigHead text-center mb-5">What all is in store for you ?</h2>
            <div className="row">
              <div className="col-md-4">
                <InnerCard headImg={bgautomated} pageheading="Automated Price Management" pagelink={AllRoutes.automatedpricingpage} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bgunified} pageheading="Unified Pricing Engine" pagelink={AllRoutes.unifiedpricingpage} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bggoal} pageheading="Goal Driven Pricing" pagelink={AllRoutes.goalbasedpage} />

              </div>
              {/* col-4 end */}
            </div>
          </div>
        </section>

      
        <section className="form-section">
          <div className="container" data-aos="new-animation">
            <div className="text-container">
              <h2 className="bigHead mb-5">Curious to know more about us!</h2>
              <p>Explore more about how our pricing experts  can scale your revenue to soaring heights just by pricing right.</p>
            </div>
            <div className="form-container">
              <Form {...formProps} />
            </div>
          </div>
        </section>
      </HelmetProvider>
    </>
  )
}
export default AutomatedDp;