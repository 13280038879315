import Footer from "../common/footer";
import Header from "../common/header";
import Ticker from "../common/ticker";
import { Button } from 'react-bootstrap';
import AllRoutes from "../../AllRoutes";
import InnerCard from "../retail/innercard";
import img1 from "../../assets/img/retail/automated-pricing/Real-Time-Market-Monitoring-updated.png";
import img2 from "../../assets/img/retail/automated-pricing/assortment_availability.png";
import img3 from "../../assets/img/retail/automated-pricing/product_matching.png";
import cta_bg from '../../assets/img/retail/automated-pricing/bg_cta.png'
import shopify from '../../assets/img/partner/integration_2/shopify.png'
import amazon from '../../assets/img/partner/integration_2/amazon.png'
import flipkart from '../../assets/img/partner/integration_2/flipkart.png'
import clientimg from '../../assets/img/clients/rupesh-patil.png'
import kkimg from '../../assets/img/retail-client/kisan-konnect.png'
import img4 from "../../assets/img/retail/automated-pricing/4.png";
import img5 from "../../assets/img/retail/automated-pricing/5.png";
import img6 from "../../assets/img/retail/automated-pricing/6.png";
import img7 from "../../assets/img/retail/automated-pricing/7.png";
import img8 from "../../assets/img/retail/automated-pricing/8.png";
import img9 from "../../assets/img/retail/automated-pricing/9.png";
import bggoal from "../../assets/img/store/goal-driven-pricing.png";
import bgunified from "../../assets/img/store/unified-pricing.png";
import bgcomp from "../../assets/img/store/competition-intelligence.png";
import bgautomated from "../../assets/img/store/automated-pricing.png";
import { GlobalUrl, GlobalImg } from "../../global";

import { Tab, Col, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Recoginition from "../retail/recognition";
import CapteraAchievements from "../travel/capterra-achievvement";
import RetailHeader from "../retail/retailHeader";
import RetailClient from "../retail/retailCLient";
import AOS from "aos";
import { useEffect, useState } from "react";
import "aos/dist/aos.css";
import QAScore from "../retail/QAScore";
import InnerHeroRetail from "../retail/innerHeroRetail";
import RetailInsideNumber from "../retail/retailInsideNumber";
import RetailBenefits from "../retail/retailBenefits";
import MarketPlaceIntegration from "../retail/marketPlaceIntegration";
import RetailTestimonial from "../retail/retailTestimonial";
import Form from "../Forms/Form";
import { toast } from "react-toastify";
import { Helmet, HelmetProvider } from "react-helmet-async";
import NavigationSection from "../retail/NavigationSection";
import BenefitSection from "../retail/BenefitSection";
import b1 from "../../assets/img/icon/competitive.png";
import b2 from "../../assets/img/icon/crawling.png";
import b3 from "../../assets/img/icon/Assortment.png";
import b4 from "../../assets/img/icon/Analysis.png";
import b5 from "../../assets/img/icon/competitive.png";
import b6 from "../../assets/img/icon/Alerts.png";
import pimg1 from "../../assets/img/picon/1.png";
import pimg2 from "../../assets/img/picon/2.png";
import pimg3 from "../../assets/img/picon/3.png";
import pimg4 from "../../assets/img/picon/4.png";
import pimg5 from "../../assets/img/picon/5.png";
import ProblemSection from "../retail/ProblemSection";
const CompPriceTrack = ({ }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])
  const end_point = "competitive-price-tracking";

  const qa_list = [
    "Have you experienced challenges in adjusting prices quickly in response to competitor pricing changes?",
    "Do you find it difficult to track and monitor pricing data across multiple products and categories?",
    "Have you missed opportunities to optimize prices based on real-time market demand and trends?",
    "Is it challenging to ensure pricing consistency across different sales channels and locations?",
  ];

  const formProps = {
    template: 4,
    form_name: 'retail-inside-pages',
    fields: [
      { name: 'name', required: true },
      { name: 'email', required: true },
      { name: 'company', required: true },
      { name: 'phone', required: true },
      { name: 'message', required: true },
    ],
    recipients_group_id: 1,
    handleApiResponse: (res) => {
      toast.dismiss();
      if (res.status == "success"){
        toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
      } else {
        toast.error(res.data)
    }
    }
  }
  const navigation_section_props = {
    id: "comp_tracking",
    heading: "Industry problems that Our Competitive Price Tracking Solution Solves",
    tabs: [
        {
          title: "Loss of competitiveness due to lack of real-time insights.",
          img: pimg1 ,
          alt: "Loss of competitiveness due to lack of real-time insights.",
          correcttitle: "BRIO closes the insight gap! Real-time data keeps you competitive with smarter decisions."
        },
        {
          title: "Missed opportunities to capitalise on market trends and customer preferences.",
          img: pimg2,
          alt: "Missed opportunities to capitalise on market trends and customer preferences.",
          correcttitle: "BRIO captures every trend! Capitalize on market shifts and customer preferences with AI."
        },
        {
          title: "Limited understanding of market dynamics and consumer behaviour leads.",
          img: pimg3,
          alt: "Limited understanding of market dynamics and consumer behaviour leads.",
          correcttitle: "BRIO unlocks market secrets! Gain deep understanding of dynamics and consumer behavior."
        },
        {
          title: "Reduced/stagnant revenue generation due to incorrect pricing.",
         
          img: pimg4,
          alt: "Reduced/stagnant revenue generation due to incorrect pricing.",
          correcttitle: "BRIO optimizes pricing, boosts revenue! AI-powered data sets the perfect price, every time."
        },
        {
          title: "Increased risk of overpricing or underpricing products.",
         
          img: pimg5,
          alt: "Increased risk of overpricing or underpricing products.",
          correcttitle: "BRIO ensures optimal pricing! Real-time competitive tracking prevents overpricing and underpricing, keeping your prices just right."
        },
    ]
  }
  const benefit_props = {
    id: "comp_price",
    heading: "Benefits of Using BRIO",
    tabs:[
      {
        title: "Strategic Competitive Position",
        desc: "Create your customised  library of common pricing rules and constraints",
        img: b1 ,
        alt: "Strategic Competitive Position Image" 
      },
      {
        title: "Comprehensive Web Crawling",
        desc: "Our AI-driven web crawlers provide real-time competition intelligence, tracking competitor's pricing and market changes, with advanced competitor intelligence tools.",
        img: b2 ,
        alt: "Comprehensive Web Crawling" 
      },
      {
        title: "Comparative Assortment Analysis",
        desc: "Craft targeted marketing and pricing strategies that reflect the distinctiveness of your product range to seize market opportunities.",
        img: b3 ,
        alt: "Comparative Assortment Analysis" 
      },
      {
        title: "Competitor Analysis and Alerts",
        desc: "Empower businesses with data-driven decision-making tools to further enhance their market presence and competitiveness.",
        img: b4 ,
        alt: "Competitor Analysis and Alerts" 
      },
      {
        title: "Dynamic Competitiveness Score",
        desc: "Employs a dynamic scoring system that adapts to changes in the market. It categorizes each product into either Lower (L), Competitive (C), or Higher (H) based on the real-time market.",
        img: b5 ,
        alt: "Dynamic Competitiveness Score" 
      },
      {
        title: "Automated Alerts",
        desc: "Our automated alerts and notifications, enabling you to stay proactive and responsive to market dynamics.",
        img: b6 ,
        alt: "Automated Alerts" 
      },

    ]
  }
  const navigation_section_props_2 = {
    id: "compptracking",
    heading: "How Does BRIO Enhances Market Awareness and Competitive Positioning?",
    tabs: [
        {
          title: "Real Time Market Monitoring",
          desc: "Bolster your competition presence by real-time monitoring of price fluctuations, product availability, trends, and competitor pricing. ",
          img: img1 ,
          alt: "Real Time Market Monitoring" 
        },
        {
          title: "Assortment and Availability",
          desc: "Uncover what makes your products unique in the market by diving into a comparative analysis of your product assortment against competitors.",
          img: img2,
          alt: "Assortment and Availability"
        },
        {
          title: "Product Matching",
          desc: "Harness the power of our Advanced Matching Engine, boasting a remarkable 99% accuracy rate in global tracking and competitor product comparison, thereby reducing human errors and increasing efficiency.",
          img: img3,
          alt: "Product Matching"
        }
    ]
  }
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Competitive Price Tracking Software for Retail | Sciative</title>
          <link rel="canonical" href={`${GlobalUrl}${AllRoutes.comppricetrack}`} />
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="csrf_token" content="" />
          <meta property="type" content="website" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="_token" content="" />
          <meta name="robots" content="noodp" />
          <meta name='description' content="Gain a competitive edge with Sciative. Our software tracks competitor prices and provides actionable data to ensure you're always priced competitively." />
          <meta property="og:locale" content="en_US" />
          <meta property="og:title" content="Competitive Price Tracking Software for Retail | Sciative" />
          <meta property="og:description" content="Gain a competitive edge with Sciative. Our software tracks competitor prices and provides actionable data to ensure you're always priced competitively." />
         
          <meta property="og:hashtag" content="#ai" />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:url" content={`${GlobalUrl}${AllRoutes.comppricetrack}`} />
          <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
          <meta property="og:image"  content={GlobalImg} />
          <meta property="og:type" content="website" />
          <script type="application/ld+json">
              {`
               {
                "@context": "https://schema.org/", 
                "@type": "BreadcrumbList", 
                "itemListElement": [{
                  "@type": "ListItem", 
                  "position": 1, 
                  "name": "Home",
                  "item": "https://sciative.com"  
                },{
                  "@type": "ListItem", 
                  "position": 2, 
                  "name": "Retail",
                  "item": "https://sciative.com/retail"  
                },{
                  "@type": "ListItem", 
                  "position": 3, 
                  "name": "solution",
                  "item": "https://sciative.com/retail/competitive-price-tracking"  
                }]
              }
              
              `}
            </script>
        </Helmet>
        <InnerHeroRetail end_point={end_point} page_name="pricingautomation" page_heading="Competitive Price Tracking" page_desc1="Stay Ahead of the Curve: Real-Time Competitive Price Tracking " page_desc2="at Your Fingertips." button_name="Get your hands on Price Tracking" />
        <RetailClient />

      
       <ProblemSection {...navigation_section_props} />

        <section className="client_det inside_det">
          <div className="container" data-aos="new-animation">
            <h2 className="bigHead mb-5">We Deliver, Our Customers ‘ Speak </h2>
            <div className="card">

              <p className="client_test">Using BRIO's competitive price tracking feature has been a game-changer for our beauty and wellness brand. We've seen significant improvements across key areas, such as a 25% increase in sales due to strategic pricing adjustments based on competitor insights. Additionally, our product visibility has soared by 40% as we've been able to position ourselves competitively in the market. BRIO's solution has truly empowered us to make informed decisions, stay ahead of the competition, and drive remarkable growth in our industry.</p>
              <div className="flex_card">
                <div className="card_1">
                  {/* <img src={clientimg} alt="Rupesh Patil" /> */}
                  <div className="client_desc">
                    <p className="namec">Private Label Fashion Brand</p>
                    <p className="named">North America</p>
                  </div>
                </div>

                {/* <img src={kkimg} alt="" className="kk_img" /> */}
              </div>
            </div>
            <div className="text-center mt-5">
              <p className="bold">If they could, even your brand can!</p>
              <Link to={`/retail/book-a-demo?navigation=${end_point}`}>
                <button class="yellow-button">Connect now</button>
              </Link>
            </div>
          </div>
        </section>
        <RetailInsideNumber end_point={end_point} />
        {/* <RetailBenefits /> */}
       <BenefitSection {...benefit_props} />


        {/* <section className="client_det auto_price">
            <div className="container">
                <h2 className="bigHead mb-5">We Deliver, Our Customers ‘ Speak </h2>
                <div className="card">
                    <div className="flex_card">
                        <div className="card_1">
                            <img src={clientimg} alt="Rupesh Patil" />
                            <div className="client_desc">
                                <p className="namec">Rupesh Patil,</p>
                                <p className="named">Senior Manager MIS, Costing & Business Development</p>
                            </div>
                        </div>
                       
                        <img src={kkimg} alt="" className="kk_img" />
                    </div>
                    <p className="client_test">What I liked best about working with Sciative is their quest to understand the Customer’s need rather than pushing any of their products (which are great, by the way). The team is absolutely professional and the output work is very commendable. It has helped our business take key timely decisions.</p>
                </div>
                <div className="text-center client_det_para">
                        <p>If they could, even your brand can!</p>
                        <Link to='/retail/book-a-demo' className="yellow-button">Connect Now</Link>
                </div>
            </div>
        </section> */}
        <CapteraAchievements props={"brio_inside"} />
        <NavigationSection {...navigation_section_props_2} />
        <MarketPlaceIntegration  end_point={end_point} />

        {/* <section className="retail_bg_cta">
          <div className="container" data-aos="new-animation">
            <div className="card ap_card">
              <p className="card_text">Let Your Numbers Also Speak for Your Brand Success</p>
              <Link to={`/retail/book-a-demo?navigation=${end_point}`}>
                <button className="yellow-button">Book a Demo Now</button>
              </Link>
            </div>
          </div>
        </section> */}
        <section className="qa_store">
          <div className="container" data-aos="new-animation">
            <h2 className="bigHead text-center mb-5">What all is in store for you ?</h2>
            <div className="row">
            <div className="col-md-4">
                <InnerCard headImg={bgautomated} pageheading="Automated Price Management" pagelink={AllRoutes.automatedpricingpage} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bgunified} pageheading="Unified Pricing Engine" pagelink={AllRoutes.unifiedpricingpage} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bggoal} pageheading="Goal Driven Pricing" pagelink={AllRoutes.goalbasedpage} />

              </div>
              {/* col-4 end */}
            </div>
          </div>
        </section>
     
        <section className="form-section">
          <div className="container" data-aos="new-animation">
            <div className="text-container">
              <h2 className="bigHead mb-5">Curious to know more about us!</h2>
              <p>Explore more about how our pricing experts  can scale your revenue to soaring heights just by pricing right.</p>
            </div>
            <div className="form-container">
              <Form {...formProps} />
            </div>
          </div>
        </section>
      </HelmetProvider>
    </>
  )
}
export default CompPriceTrack;